export const SLOT_DATA_TYPE_CAPTURE_BY_AI = "custom";
export const SLOT_DATA_TYPE_REGEX = "regex";
export const SLOT_DATA_TYPE_VALUES = "values";

export const SLOT_DATA_TYPES = [
    { label: SLOT_DATA_TYPE_CAPTURE_BY_AI, value: SLOT_DATA_TYPE_CAPTURE_BY_AI },
    { label: SLOT_DATA_TYPE_REGEX, value: SLOT_DATA_TYPE_REGEX },
    { label: SLOT_DATA_TYPE_VALUES, value: SLOT_DATA_TYPE_VALUES },
    { label: "string", value: "string" },
    { label: "number", value: "number" },
    { label: "boolean", value: "boolean" },
    { label: "email", value: "email" },
    { label: "age", value: "age" },
    { label: "date", value: "date" },
    { label: "datetime", value: "datetime" }
]

