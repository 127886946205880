import { PopoverButton } from "./popover-button";
import { toolboxFlowsTriggerButtons } from "./constants";
import JourneySlotHandleDialog from "../../../../components/journey-slot-handle-dialog";
import { useState } from "react";

export default function Toolbox({appId, journeyId}) {
    const [currentEvent, setCurrentEvent] = useState();
    const onEventTrigger = event=> {
        console.log('onEventTrigger', event)
        setCurrentEvent(event)
    }

  return (
    <menu className="flex items-center w-full justify-center border-b dark:border-gray-700">
      {toolboxFlowsTriggerButtons.map((button, index) => {
        return <PopoverButton key={index} option={button} eventTrigger={onEventTrigger} />;
      })}
        {currentEvent === 'variable' &&
            <JourneySlotHandleDialog
                appId={appId}
                journeyId={journeyId}
                onSlotsUpdated={()=>{}}
                propOpenSlotsTableDialog={true}
                onContainerClose={()=>setCurrentEvent(null)}
            />
        }
    </menu>
  );
}
