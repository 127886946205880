import {Switch} from '@headlessui/react'
import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {parseAndFormat} from "../../helpers/utils/date-util";
import {DATE_FORMAT, DATE_TIME_FORMAT} from "../../consts/common-consts";
import Loader from "../form-loader/form-loader";
import Message from "../message/message-comp";
import {useNavigate} from "react-router-dom";
import {Alert, Table} from 'flowbite-react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Index({
                                  headers = [],
                                  items = [],
                                  onChangeEnable = () => {
                                  },
                                  itemsNotFoundMessage = "Don't have any data",
                                  modifiedColumn = true,
                                  enableColumn = true,
                                  dataLoadingState = {
                                      processing: false,
                                      success: false,
                                      failed: false,
                                      errorMessage: ''
                                  },
                                  filterCondition=()=>true
                              }) {
    const navigate = useNavigate();
    const loadingMockArray = [1, 1, 1, 1];
    return (
        <>
            {dataLoadingState.processing && <div className="py-6"><Loader text="Loading..." /></div>}

            {dataLoadingState.processing &&
                <div className="pt-8 flex flex-row justify-center items-center">
                    <div className="flex-1 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                        {loadingMockArray.map((i, key) => <>
                            <div
                                className="border border-gray-300 dark-border shadow rounded-md p-4 max-w-sm w-full mx-auto">
                                <div className="animate-pulse flex space-x-4">
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                                        <div className="space-y-3">
                                            <div className="grid grid-cols-3 gap-4">
                                                <div
                                                    className="h-2 bg-slate-200 dark:bg-slate-600 rounded col-span-2"></div>
                                                <div
                                                    className="h-2 bg-slate-200 dark:bg-slate-600 rounded col-span-1"></div>
                                            </div>
                                            <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
            }

            {(!dataLoadingState.processing && items.length === 0) &&
                <div className="pt-8">
                    <Alert
                        color="dark"
                        rounded
                    >
                    <span>
                        <p>
                            {itemsNotFoundMessage}
                        </p>
                    </span>
                    </Alert>
                </div>

            }

            {(!dataLoadingState.processing && items.length > 0) &&
                <div className="pt-8 h-screen">
                <div
                    className="border-x border-y primary-border-color rounded-lg dark:border-0">
                    <Table className="dark:bg-gray-800">
                        <Table.Head>
                            {headers.map(((h, index) =>
                                    <Table.HeadCell>
                                        {h}
                                    </Table.HeadCell>
                            ))}

                            {modifiedColumn &&
                                <Table.HeadCell>
                                    Updated At
                                </Table.HeadCell>
                            }

                            <Table.HeadCell>
                                          <span className="sr-only">
                                            Edit
                                          </span>
                            </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                            {items.map((item, index) => {
                                const {record, columnsData} = item;
                                const tds = [];
                                for (const cd of columnsData) {
                                    const {data, isEnableField, options, isIdField, isUpdatedField} = cd;
                                    let tdElement;
                                    if (options && options.length > 0) {
                                        tdElement =
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-row justify-end">
                                                <Menu as="div" className="absolute inline-block text-left">
                                                    <div>
                                                        <Menu.Button
                                                            className="flex items-center rounded-full text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                            <span className="sr-only">Open options</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 fill="none" viewBox="0 0 24 24"
                                                                 strokeWidth={1.5} stroke="currentColor"
                                                                 className="w-7 h-7">
                                                                <path strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"/>
                                                            </svg>
                                                        </Menu.Button>
                                                    </div>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            className="absolute z-50 right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                {options.map((option, oi) =>
                                                                    <Menu.Item key={oi}>
                                                                        {({active}) => (
                                                                            <a
                                                                                href="#"
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'block px-4 py-2 text-sm'
                                                                                )}
                                                                                onClick={() => {
                                                                                    if (option.link) {
                                                                                        navigate(option.link);
                                                                                    } else {
                                                                                        option.onClick(record);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {option.name}
                                                                            </a>
                                                                        )}
                                                                    </Menu.Item>
                                                                )}
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </td>
                                    } else if (modifiedColumn && isUpdatedField) {
                                        tdElement = <Table.Cell
                                            className="px-6 py-4 whitespace-nowrap dark-primary-text">
                                            {parseAndFormat(data, DATE_TIME_FORMAT)}
                                        </Table.Cell>
                                    } else {
                                        tdElement = <Table.Cell className="dark-primary-text">
                                            {isIdField ?
                                                <span className="font-bold">{data}</span>
                                                : <span>{data}</span>}
                                        </Table.Cell>
                                    }

                                    if (filterCondition(record)) {
                                        tds.push(tdElement);
                                    }

                                }

                                return (<Table.Row>{tds}</Table.Row>);
                            })}
                        </Table.Body>
                    </Table>
                </div>
                </div>
            }
        </>
    )
}