import {LogicIcon, SettingIcon} from "../../../common/components/icons";

export function SettingNodeIcon() {
    return (
        <div className="text-gray-500">
            <SettingIcon/>
        </div>
    )
}

export function LogicNodeIcon() {
    return (
        <div className="text-gray-500">
            <LogicIcon/>
        </div>
    )
}