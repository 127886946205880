export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_STR_FORMAT = 'LLLL dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm aa';
export const DEFAULT_APP_VERSION = 'v1';

export const formDataRequestType = { label: "Form Data", value: "form_data" }
export const rawRequestType = { label: "Raw", value: "raw" }
export const requestTypes = [
    formDataRequestType,
    rawRequestType
]