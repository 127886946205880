import config from "../config";
export const API_PREFIX = `${config.API_BASE_URL}`;

// apps
export const APPS_ENDPOINT = `${API_PREFIX}/applications`;


// journeys
export const JOURNEYS_ENDPOINT = `${API_PREFIX}/journeys`;

// users
export const USERS_ENDPOINT = `${API_PREFIX}/users`;

// configs
export const CONFIGS_ENDPOINT = `${API_PREFIX}/configs`;

// error codes
export const HTTP_CLIENT_ERROR = '500';
