import {HiInformationCircle} from "react-icons/hi";
import {Alert} from "flowbite-react";

const customTheme = {
    "color": {
        "custom": "text-gray-700 bg-gray-100 border-gray-500 dark:bg-gray-200 dark:text-gray-800",
    },
};

export default function SettingTip ({text}) {
    return <div className="mb-2">
        <Alert
            color="gray"
            icon={HiInformationCircle}
            theme={customTheme}
        >
      <span>
        <p>
          <span className="font-medium">
            {text}
          </span>
        </p>
      </span>
        </Alert>
    </div>
}