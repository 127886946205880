import React, {useState, useEffect} from 'react';
import Button from "../../components/button/button";
import JourneySlotTable from "./journey-slot-table";
import JourneySlotAssign from "./journey-slot-assign";
import {connect} from "react-redux";

function Page({
                  appId,
                  journeyId,
                  onSlotsUpdated,
                  stmSlotCreateRequestMessage,
                  onContainerClose,
}) {
    const [ openSlotsTableDialog, setOpenSlotsTableDialog] = useState(true);

    const [ openSlotCreateDialog, setSlotOpenCreateDialog] = useState(false);
    const [ editSlotData, setEditSlotData ] = useState();

    useEffect( () => {
        if (stmSlotCreateRequestMessage) {
            console.log("stmSlotCreateRequestMessage", stmSlotCreateRequestMessage)
            handleOpenSlotCreate()
        }
    }, [stmSlotCreateRequestMessage]);

    const handleOpenSlotCreate = () => {
        setOpenSlotsTableDialog(false)

        setSlotOpenCreateDialog(true);
        setEditSlotData()
    }

    const handleOnCompletionSlotCreate = async state => {
        setOpenSlotsTableDialog(true)

        setEditSlotData()
        setSlotOpenCreateDialog(false);

        if (state) {
            onSlotsUpdated(state)
        }
    }

    const handleSetSlotData = data => {
        setOpenSlotsTableDialog(false)

        setEditSlotData(data);
        setSlotOpenCreateDialog(true);
    }

    const handleOpenSlotsTableDialog = () => {
        setOpenSlotsTableDialog(true);
    }

    const onCancelSlotsTableDialog = () => {
        setOpenSlotsTableDialog(false);
        onContainerClose()
    }

    return (
        <div>
            {/*{!propOpenSlotsTableDialog &&*/}
            {/*    <Button color="default" text="Slots" onClick={handleOpenSlotsTableDialog} />*/}
            {/*}*/}
            {(openSlotCreateDialog) &&
                <JourneySlotAssign
                    appId={appId}
                    journeyId={journeyId}
                    open={openSlotCreateDialog}
                    savedData={editSlotData}
                    onCompletion={handleOnCompletionSlotCreate}
                />
            }

            {openSlotsTableDialog &&
                <JourneySlotTable
                    open={openSlotsTableDialog}
                    appId={appId}
                    journeyId={journeyId}
                    handleSetEditData={handleSetSlotData}
                    handleOpenSlotCreate={handleOpenSlotCreate}
                    onCancel={onCancelSlotsTableDialog}
                />
            }

        </div>
    )
}

const mapsStateToProps = (state, ownProps) => {
    return {
        stmSlotCreateRequestMessage: state.stmSlotCreateRequestMessage
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapsStateToProps, mapDispatchToProps)(Page)