import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";
import WebhookActionSettings from "../../settings/webhook-action-settings";

export function WebhookActionNode({ id, data }) {
    return (
        <NodeComponent title="Webhook Action" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <WebhookActionSettings/>
        </NodeComponent>
    );
}

