import MessagePromptSettings from "./message-prompt-settings";
import QuestionPromptSettings from "./question-prompt-settings";
import WebhookPromptSettings from "./webhook-prompt-settings";
import WebhookActionSettings from "./webhook-action-settings";
import ChoiceSettings from "./choice/choice-settings";

import {
    CHOICE_NODE_TYPE,
    MESSAGE_PROMPT_NODE_TYPE,
    QUESTION_PROMPT_NODE_TYPE,
    WEBHOOK_ACTION_NODE_TYPE,
    WEBHOOK_PROMPT_NODE_TYPE
} from "../common/const/node-types";

const settingsTypes = { };
settingsTypes[MESSAGE_PROMPT_NODE_TYPE] = { title: "Text Message", component: <MessagePromptSettings/>, icon: null};
settingsTypes[QUESTION_PROMPT_NODE_TYPE] =  { title: "Question", component: <QuestionPromptSettings/>, icon: null};
settingsTypes[WEBHOOK_PROMPT_NODE_TYPE] = { title: "Webhook", component: <WebhookPromptSettings/>, icon: null};
settingsTypes[WEBHOOK_ACTION_NODE_TYPE] = { title: "Webhook", component: <WebhookActionSettings/>, icon: null};
settingsTypes[CHOICE_NODE_TYPE] = { title: "Condition", component: <ChoiceSettings/>, icon: null};

export {
    settingsTypes
}