import React, {useEffect, useState} from "react";
import {useAppInfo} from "../../../../../../helpers/hooks/common-hook";
// import Button from "../../../../../components/button/button";
import DynamicRows from "../../../../../../components/dynamic-rows";
import TextArea from "../../../../../../components/forms/text-area";

const prepareRawsData = (data=[]) => {
    return data.map(header=>({inputs: header}))
}

export default function DisplayTexts({
                                         parameterName,
                                         displayTextOptionsProps=[],
                                         rootClassName="",
                                         onChangeData
                                  }) {
    const { appId } = useAppInfo();

    const [displayTextOptions, setDisplayTextOptions] = useState({
        data: [{ displayText: "" }]
    });
    const [numOfOptions, setNumOfOptions] = useState(3);

    function getPrompt() {
        let prompt;
        if (displayTextOptions
            &&
            displayTextOptions.data
            && displayTextOptions.data.length > 0 && displayTextOptions.data[0].displayText) {
            prompt = `rewrite and fix grammar of this text '${displayTextOptions.data[0].displayText}'`
        }  else if (parameterName) {
            prompt = `write question asking ${parameterName}`
        } else {
            return null;
        }
        return `${prompt}. need ${numOfOptions} variants`;
    }

    const onChangeResponseMapper = (rowsData=[]) => {
        rowsData = rowsData.map(raw=>raw.inputs)
        displayTextOptions.data = rowsData;
        onChangeData(displayTextOptions.data)
    }

    useEffect(() => {

        let _displayTextOptions = []
        if (displayTextOptionsProps && displayTextOptionsProps.length > 0) {
            _displayTextOptions = displayTextOptionsProps;
        } else {
            _displayTextOptions = [{ displayText: "" }]
        }

        const formData = {...displayTextOptions, data: _displayTextOptions }
        setDisplayTextOptions(formData)
    }, [displayTextOptionsProps]);

    const [textGenResult, setTextGenResult] = useState();

    const [textGenState, setTextGenState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });


    const updateDataLoadingState = (processing, success, failed, message) => {
        setTextGenState({processing, success, failed, message})
    }

    useEffect(() => {
        try {
            const formData = { data: textGenResult.result.map(r => ({ displayText: r })) }
            setDisplayTextOptions(formData)
        } catch (err) {}
    }, [textGenResult]);

    return (
        <div className={rootClassName}>
            <DynamicRows
                title="Display Text Variants"
                className="pt-2 pb-4"
                removeButtonClassName="pt-0 flex-row justify-end"
                rowsData={prepareRawsData(displayTextOptions.data)}
                onChange={onChangeResponseMapper}
                showBottomDivider={false}
                rowsTemplate={(valuesMap, handleInputChange) => (
                    <div className="flex flex-row items-center gap-2 w-full pr-2">
                        <TextArea
                            rootClass="w-full"
                            label=""
                            type="text"
                            name="displayText"
                            placeholder="User display text"
                            value={valuesMap.displayText}
                            onChange={handleInputChange}
                        />
                    </div>
                )}
            />
        </div>)
}