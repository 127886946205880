import {useAppContext} from '../helpers/hooks/common-hook';

// import DashboardPage from "./dashboard/backup-dashboard-page";
import DashboardPage from "./dashboard/dashboard-page";

const MainRouter = () => {
    const { authUserInfo } = useAppContext();
    if (authUserInfo) {
        // logged user
        return <DashboardPage />
    } else {
        // not logged
        return (
            // <Routes>
            //     <Route path='/' element={<SigninPage />} />
            //     <Route path='/signin' element={<SigninPage />} />
            //     <Route path='/signup' element={<SignupPage />} />
            //     <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            //     <Route path='/request-access' element={<RequestEarlyAccess />} />
            //     <Route path='/contact-us' element={<ContactUsPage />} />
            // </Routes>
            <div>No-Auth</div>
        );
    }
};

export default MainRouter;
