import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";
import WebhookPromptSettings from "../../settings/webhook-prompt-settings";

export function WebhookPromptNode({ id, data }) {
    return (
        <NodeComponent title="Webhook" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <WebhookPromptSettings/>
        </NodeComponent>
    );
}

