export class StmStateDto {
    constructor({
                    type,
                    stateConfig,
                }) {
        this.type = type;
        this.stateConfig = stateConfig;
    }
}

export class StmChoiceStateDto extends StmStateDto{
    constructor({
                    type,
                    stateFlowConfig,
                    rules
                }) {
        super({
            type,
            stateFlowConfig,
        })
        this.rules = rules;
    }
}