import './style.css';
import NodeComponent from "../node";
import {LogicNodeIcon} from "./icons";
import ChoiceSettings from "../../settings/choice/choice-settings";

export function ChoiceNode({ id, data }) {
    return (
        <NodeComponent title="Condition" id={id} data={data} nodeTypeIcon={<LogicNodeIcon/>}>
            <ChoiceSettings/>
        </NodeComponent>
    );
}

