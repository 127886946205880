const template = {
    "topic": "greet",
    "content": "hi, hello, hey",
    "workflow": {
    "startAt": "sbds1fdtae",
        "states": {
        "sbds1fdtae": {
            "type": "message",
                "stateConfig": {
                "type": "text",
                    "name": "message",
                    "displayTextOptions": [
                    {
                        "displayText": "Hi, how can I assist you today?"
                    },
                    {
                        "displayText": "Hello, how may I help you today?"
                    }
                ]
            }
        }
    },
    "content": "hi, hello, hey",
        "metadata": {
        "nodes": [
            {
                "width": 288,
                "height": 122,
                "id": "sbds1fdtae",
                "type": "messagePrompt",
                "data": {
                    "id": "sbds1fdtae",
                    "type": "messagePrompt",
                    "nodeIndex": 1,
                    "name": "New Step 1",
                    "metadata": {
                        "journeyId": "greet",
                        "slots": []
                    },
                    "selected": true
                },
                "position": {
                    "x": 300,
                    "y": 60
                },
                "sourcePosition": "right",
                "targetPosition": "left",
                "selected": true,
                "dragging": false,
                "positionAbsolute": {
                    "x": 300,
                    "y": 60
                }
            }
        ],
            "edges": [],
            "journeyStmStore": {
            "sbds1fdtae": {
                "type": "message",
                    "stateConfig": {
                    "type": "text",
                        "name": "message",
                        "displayTextOptions": [
                        {
                            "displayText": "Hi, how can I assist you today?"
                        },
                        {
                            "displayText": "Hello, how may I help you today?"
                        }
                    ]
                }
            }
        }
    },
    "modifiedAt": "2024-09-06T17:12:48Z"
},
    "metadata": {
    "nodes": [
        {
            "width": 288,
            "height": 122,
            "id": "sbds1fdtae",
            "type": "messagePrompt",
            "data": {
                "id": "sbds1fdtae",
                "type": "messagePrompt",
                "nodeIndex": 1,
                "name": "New Step 1",
                "metadata": {
                    "journeyId": "greet",
                    "slots": []
                },
                "selected": true
            },
            "position": {
                "x": 300,
                "y": 60
            },
            "sourcePosition": "right",
            "targetPosition": "left",
            "selected": true,
            "dragging": false,
            "positionAbsolute": {
                "x": 300,
                "y": 60
            }
        }
    ],
        "edges": [],
        "journeyStmStore": {
        "sbds1fdtae": {
            "type": "message",
                "stateConfig": {
                "type": "text",
                    "name": "message",
                    "displayTextOptions": [
                    {
                        "displayText": "Hi, how can I assist you today?"
                    },
                    {
                        "displayText": "Hello, how may I help you today?"
                    }
                ]
            }
        }
    }
}
}

export default template