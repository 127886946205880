const template = {
  "topic": "appointment-book",
  "content": "appointment book, can i book appointment",
  "workflow": {
    "startAt": "25y88a8q0y",
    "states": {
      "kz9nxze2bk": {
        "type": "prompt",
        "next": "rwjfgwhkt1",
        "stateConfig": {
          "name": "ask-name",
          "slot": "name",
          "displayTextOptions": [
            {
              "displayText": "May I have your name, please?"
            }
          ],
          "type": "text"
        }
      },
      "rwjfgwhkt1": {
        "type": "prompt",
        "next": "2psord8xqf",
        "stateConfig": {
          "name": "ask-email",
          "slot": "email",
          "displayTextOptions": [
            {
              "displayText": "Thanks,{name}. Can you share your email ?"
            }
          ],
          "type": "text"
        }
      },
      "2psord8xqf": {
        "type": "prompt",
        "next": "jggaks6ira",
        "stateConfig": {
          "name": "ask-appointmentType",
          "slot": "appointmentType",
          "displayTextOptions": [
            {
              "displayText": "Could you please specify the service you are looking for: Echocardiogram, ECG, Exercise Stress Test, Ambulatory Blood Pressure Monitoring, Holter Monitoring, or Consultations?"
            }
          ],
          "type": "text"
        }
      },
      "jggaks6ira": {
        "type": "prompt",
        "next": "5nbt955vpf",
        "stateConfig": {
          "name": "ask-dateTime",
          "slot": "dateTime",
          "displayTextOptions": [
            {
              "displayText": "When are you planning to schedule the appointment ?"
            }
          ],
          "type": "text"
        }
      },
      "icczp2xeuf": {
        "type": "choice",
        "next": "kia7tl4q9w",
        "stateConfig": {
          "choices": [
            {
              "Variable": "confirm",
              "StringMatches": "yes",
              "Next": "kia7tl4q9w"
            }
          ],
          "defaultState": "mtob510dku"
        }
      },
      "5nbt955vpf": {
        "type": "prompt",
        "next": "icczp2xeuf",
        "stateConfig": {
          "name": "confirm",
          "slot": "confirm",
          "displayTextOptions": [
            {
              "displayText": "Thank you for providing the information. Summary of your appointment: Email: {email}, Name: {name}, Service: {appointmentType}, Date and Time: {dateTime}. Please confirm the details. Answer yes/no."
            }
          ],
          "type": "text"
        }
      },
      "25y88a8q0y": {
        "type": "message",
        "next": "kz9nxze2bk",
        "stateConfig": {
          "type": "text",
          "name": "welcome",
          "displayTextOptions": [
            {
              "displayText": "Of course, I can assist in scheduling an appointment."
            }
          ]
        }
      },
      "mtob510dku": {
        "type": "message",
        "next": "kz9nxze2bk",
        "stateConfig": {
          "type": "text",
          "name": "updateMessage",
          "displayTextOptions": [
            {
              "displayText": "Please update your information"
            }
          ]
        }
      },
      "kia7tl4q9w": {
        "type": "action",
        "next": "jtm04a6oiq",
        "stateConfig": {
          "name": "send-email",
          "type": "webhook",
          "slotToAssign": "",
          "skipNotify": true,
          "pauseAfterExecution": false,
          "defaultInput": "{}",
          "webhookConfig": {
            "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
            "method": "POST",
            "parameters": [],
            "requestFormData": [],
            "requestBody": "{\n  \"clinicEmail\": \"CLINICEMAIL\",\n  \"email\": {email},\n  \"name\": {name},\n  \"appointmentType\": {appointmentType},\n  \"dateTime\": {dateTime},\n}",
            "requestType": "raw",
            "headers": [],
            "responseMapping": []
          },
          "requestMapper": {
            "type": "inline_script",
            "inlineScript": ""
          },
          "responseMapper": {
            "type": "inline_script",
            "inlineScript": ""
          }
        }
      },
      "jtm04a6oiq": {
        "type": "message",
        "stateConfig": {
          "type": "text",
          "name": "finalMessage",
          "displayTextOptions": [
            {
              "displayText": "Thank you for connecting with us."
            }
          ]
        }
      }
    }
  },
  "metadata": {
    "nodes": [
      {
        "width": 288,
        "height": 122,
        "id": "jtm04a6oiq",
        "type": "messagePrompt",
        "data": {
          "id": "jtm04a6oiq",
          "type": "messagePrompt",
          "nodeIndex": 10,
          "name": "New Step 1",
          "metadata": {
            "journeyId": "appointment-book",
            "slots": []
          },
          "selected": true
        },
        "position": {
          "x": 3070,
          "y": 280
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": true,
        "positionAbsolute": {
          "x": 3070,
          "y": 280
        },
        "dragging": false
      },
      {
        "width": 288,
        "height": 122,
        "id": "25y88a8q0y",
        "type": "messagePrompt",
        "data": {
          "id": "25y88a8q0y",
          "type": "messagePrompt",
          "nodeIndex": 9,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": -320,
          "y": -40
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "dragging": false,
        "positionAbsolute": {
          "x": -320,
          "y": -40
        }
      },
      {
        "width": 288,
        "height": 122,
        "id": "icczp2xeuf",
        "type": "choice",
        "data": {
          "id": "icczp2xeuf",
          "type": "choice",
          "nodeIndex": 8,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 2170,
          "y": 560
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "dragging": false,
        "positionAbsolute": {
          "x": 2170,
          "y": 560
        }
      },
      {
        "width": 288,
        "height": 122,
        "id": "mtob510dku",
        "type": "messagePrompt",
        "data": {
          "id": "mtob510dku",
          "type": "messagePrompt",
          "nodeIndex": 7,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 2410,
          "y": 1200
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "dragging": false,
        "positionAbsolute": {
          "x": 2410,
          "y": 1200
        }
      },
      {
        "width": 288,
        "height": 122,
        "id": "kia7tl4q9w",
        "type": "webhookAction",
        "data": {
          "id": "kia7tl4q9w",
          "type": "webhookAction",
          "nodeIndex": 6,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 2620,
          "y": 280
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "positionAbsolute": {
          "x": 2620,
          "y": 280
        },
        "dragging": false
      },
      {
        "width": 288,
        "height": 122,
        "id": "5nbt955vpf",
        "type": "questionPrompt",
        "data": {
          "id": "5nbt955vpf",
          "type": "questionPrompt",
          "nodeIndex": 5,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 1690,
          "y": 510
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "positionAbsolute": {
          "x": 1690,
          "y": 510
        },
        "dragging": false
      },
      {
        "width": 288,
        "height": 122,
        "id": "jggaks6ira",
        "type": "questionPrompt",
        "data": {
          "id": "jggaks6ira",
          "type": "questionPrompt",
          "nodeIndex": 4,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 1340,
          "y": 400
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "positionAbsolute": {
          "x": 1340,
          "y": 400
        },
        "dragging": false
      },
      {
        "width": 288,
        "height": 122,
        "id": "2psord8xqf",
        "type": "questionPrompt",
        "data": {
          "id": "2psord8xqf",
          "type": "questionPrompt",
          "nodeIndex": 3,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 950,
          "y": 280
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "positionAbsolute": {
          "x": 950,
          "y": 280
        },
        "dragging": false
      },
      {
        "width": 288,
        "height": 122,
        "id": "rwjfgwhkt1",
        "type": "questionPrompt",
        "data": {
          "id": "rwjfgwhkt1",
          "type": "questionPrompt",
          "nodeIndex": 2,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 570,
          "y": 160
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "positionAbsolute": {
          "x": 570,
          "y": 160
        },
        "dragging": false
      },
      {
        "width": 288,
        "height": 122,
        "id": "kz9nxze2bk",
        "type": "questionPrompt",
        "data": {
          "id": "kz9nxze2bk",
          "type": "questionPrompt",
          "nodeIndex": 1,
          "name": "New Step 1",
          "metadata": {},
          "selected": false
        },
        "position": {
          "x": 180,
          "y": 40
        },
        "sourcePosition": "right",
        "targetPosition": "left",
        "selected": false,
        "positionAbsolute": {
          "x": 180,
          "y": 40
        },
        "dragging": false
      }
    ],
    "edges": [
      {
        "source": "kz9nxze2bk",
        "sourceHandle": "out",
        "target": "rwjfgwhkt1",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-kz9nxze2bkout-rwjfgwhkt1in",
        "selected": false
      },
      {
        "source": "rwjfgwhkt1",
        "sourceHandle": "out",
        "target": "2psord8xqf",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-rwjfgwhkt1out-2psord8xqfin",
        "selected": false
      },
      {
        "source": "2psord8xqf",
        "sourceHandle": "out",
        "target": "jggaks6ira",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-2psord8xqfout-jggaks6irain",
        "selected": false
      },
      {
        "source": "jggaks6ira",
        "sourceHandle": "out",
        "target": "5nbt955vpf",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-jggaks6iraout-5nbt955vpfin",
        "selected": false
      },
      {
        "id": "reactflow__edge-icczp2xeuf-mtob510dku",
        "source": "icczp2xeuf",
        "sourceHandle": "out",
        "target": "mtob510dku",
        "targetHandle": "in",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "type": "customEdge",
        "selected": false,
        "data": {
          "ruleName": "default"
        }
      },
      {
        "source": "5nbt955vpf",
        "sourceHandle": "out",
        "target": "icczp2xeuf",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-5nbt955vpfout-icczp2xeufin",
        "selected": false
      },
      {
        "source": "icczp2xeuf",
        "sourceHandle": "out",
        "target": "kia7tl4q9w",
        "targetHandle": "in",
        "data": {
          "ruleName": "confirm"
        },
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-icczp2xeufout-kia7tl4q9win",
        "selected": false
      },
      {
        "source": "25y88a8q0y",
        "sourceHandle": "out",
        "target": "kz9nxze2bk",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-25y88a8q0yout-kz9nxze2bkin"
      },
      {
        "source": "mtob510dku",
        "sourceHandle": "out",
        "target": "kz9nxze2bk",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-mtob510dkuout-kz9nxze2bkin"
      },
      {
        "source": "kia7tl4q9w",
        "sourceHandle": "out",
        "target": "jtm04a6oiq",
        "targetHandle": "in",
        "data": {},
        "type": "customEdge",
        "markerEnd": {
          "type": "arrowclosed"
        },
        "id": "reactflow__edge-kia7tl4q9wout-jtm04a6oiqin"
      }
    ],
    "journeyStmStore": {
      "kz9nxze2bk": {
        "type": "prompt",
        "stateConfig": {
          "name": "ask-name",
          "slot": "name",
          "displayTextOptions": [
            {
              "displayText": "May I have your name, please?"
            }
          ],
          "type": "text"
        }
      },
      "rwjfgwhkt1": {
        "type": "prompt",
        "stateConfig": {
          "name": "ask-email",
          "slot": "email",
          "displayTextOptions": [
            {
              "displayText": "Thanks,{name}. Can you share your email ?"
            }
          ],
          "type": "text"
        }
      },
      "2psord8xqf": {
        "type": "prompt",
        "stateConfig": {
          "name": "ask-appointmentType",
          "slot": "appointmentType",
          "displayTextOptions": [
            {
              "displayText": "Could you please specify the service you are looking for: Echocardiogram, ECG, Exercise Stress Test, Ambulatory Blood Pressure Monitoring, Holter Monitoring, or Consultations?"
            }
          ],
          "type": "text"
        }
      },
      "jggaks6ira": {
        "type": "prompt",
        "stateConfig": {
          "name": "ask-dateTime",
          "slot": "dateTime",
          "displayTextOptions": [
            {
              "displayText": "When are you planning to schedule the appointment ?"
            }
          ],
          "type": "text"
        }
      },
      "5nbt955vpf": {
        "type": "prompt",
        "stateConfig": {
          "name": "confirm",
          "slot": "confirm",
          "displayTextOptions": [
            {
              "displayText": "Thank you for providing the information. Summary of your appointment: Email: {email}, Name: {name}, Service: {appointmentType}, Date and Time: {dateTime}. Please confirm the details. Answer yes/no."
            }
          ],
          "type": "text"
        }
      },
      "kia7tl4q9w": {
        "type": "action",
        "stateConfig": {
          "name": "send-email",
          "type": "webhook",
          "slotToAssign": "",
          "skipNotify": true,
          "pauseAfterExecution": false,
          "defaultInput": "{}",
          "webhookConfig": {
            "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
            "method": "POST",
            "parameters": [],
            "requestFormData": [],
            "requestBody": "{\n  \"clinicEmail\": \"CLINICEMAIL\",\n  \"email\": {email},\n  \"name\": {name},\n  \"appointmentType\": {appointmentType},\n  \"dateTime\": {dateTime},\n}",
            "requestType": "raw",
            "headers": [],
            "responseMapping": []
          },
          "requestMapper": {
            "type": "inline_script",
            "inlineScript": ""
          },
          "responseMapper": {
            "type": "inline_script",
            "inlineScript": ""
          }
        }
      },
      "mtob510dku": {
        "type": "message",
        "stateConfig": {
          "type": "text",
          "name": "updateMessage",
          "displayTextOptions": [
            {
              "displayText": "Please update your information"
            }
          ]
        }
      },
      "icczp2xeuf": {
        "type": "choice",
        "stateConfig": {
          "name": "confirmCheck",
          "defaultState": "mtob510dku",
          "rules": {
            "reactflow__edge-icczp2xeufout-kia7tl4q9win": {
              "source": "icczp2xeuf",
              "target": "kia7tl4q9w",
              "edge": "reactflow__edge-icczp2xeufout-kia7tl4q9win",
              "ruleName": "confirm",
              "choice": {
                "Variable": "confirm",
                "StringMatches": "yes",
                "Next": "kia7tl4q9w"
              },
              "metadata": {
                "conditionType": "simple",
                "statements": [
                  {
                    "condition": {
                      "Variable": "confirm",
                      "StringMatches": "yes",
                      "Next": "kia7tl4q9w"
                    },
                    "metadata": {
                      "variable": "confirm",
                      "operator": "matches string",
                      "valueType": "",
                      "value": "yes",
                      "notOp": false
                    }
                  }
                ]
              }
            }
          }
        }
      },
      "25y88a8q0y": {
        "type": "message",
        "stateConfig": {
          "type": "text",
          "name": "welcome",
          "displayTextOptions": [
            {
              "displayText": "Of course, I can assist in scheduling an appointment."
            }
          ]
        }
      },
      "jtm04a6oiq": {
        "type": "message",
        "stateConfig": {
          "type": "text",
          "name": "finalMessage",
          "displayTextOptions": [
            {
              "displayText": "Thank you for connecting with us."
            }
          ]
        }
      }
    }
  }
}

export default template