import {useEffect, useState} from "react";
import {StmStateDto} from "../common/dto/stm-state-dto";
import {MESSAGE_PROMPT, QUESTION_PROMPT} from "../common/const/settings-type";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import TextArea from "../../../../components/forms/text-area";
import Message from "../../../../components/message/message-comp";
import {Alert} from "flowbite-react";
import SettingTip from "../common/components/setting-tip";
import DisplayTexts from "../common/components/display-texts";

const messageTypes = ["text"]

export default function MessagePromptSettings({name, onChangeData, savedSettings}) {

    const [formData, setFormData] = useState(savedSettings || {
        type: "text",
        name,
        displayTextOptions: [],
    });

    const notifyChanges = (_formData) => {
        onChangeData(new StmStateDto({
            type: MESSAGE_PROMPT,
            stateConfig: _formData
        }));
    }

    useEffect(() =>  {
        notifyChanges(formData);
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'type') {
            newFormData.type = targetValue;
        }

        if (targetName === 'displayText') {
            newFormData.displayText = targetValue;
        }

        setFormData(newFormData);
    };

    const handleDisplayTextsChange = data => {
        const newFormData = {...formData, displayTextOptions: data}
        notifyChanges(newFormData);
    }

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="Text message refers to the response sent to a user during an conversation"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            {/*<SelectInput*/}
            {/*    label="Type"*/}
            {/*    id="type"*/}
            {/*    name="type"*/}
            {/*    autoComplete="type"*/}
            {/*    value={formData.type}*/}
            {/*    onChange={handleChange}*/}
            {/*    optionsComp={<>*/}
            {/*        <option value={undefined}>Select Type</option>*/}
            {/*        {messageTypes.map((messateType, i)=>(*/}
            {/*            <option key={i} value={messateType}>{messateType}</option>*/}
            {/*        ))}*/}
            {/*    </>}*/}
            {/*/>*/}

            {/*<TextArea*/}
            {/*    rootClass="pt-2"*/}
            {/*    label="Display Text"*/}
            {/*    type="text"*/}
            {/*    name="displayText"*/}
            {/*    placeholder="Enter text with {slot}"*/}
            {/*    value={formData.displayText}*/}
            {/*    onChange={handleChange}*/}
            {/*    rows={10}*/}
            {/*/>*/}

            <DisplayTexts
                parameterName={formData.slot}
                displayTextOptionsProps={formData.displayTextOptions}
                onChangeData={handleDisplayTextsChange}
            />

        </div>
    )
}