import TextArea from "../forms/text-area";
import TextBox from "../forms/text-box";
import DynamicRows from "../dynamic-rows";
import {useEffect, useState} from "react";

export function TextAreaContent({content, onChange, error, label, placeholder}) {
    return <TextArea
        type="text"
        name="content"
        id="content"
        label={label}
        value={content}
        placeholder={placeholder}
        onChange={onChange}
        rows={3}
        error={error}
    />
}

export function ValuesContent({content, onChange}) {
    const [rowsData, setRowsData] = useState([]);
    const [rowsValue, setRowsValue] = useState('');

    useEffect(() => {
        if (content) {
            setRowsData(content.split(",").map(value=>({inputs: {value}})));
        }
    }, [content]);

    useEffect(() => {
        onChange({
            target: {
                name: 'content',
                value: rowsValue
            }
        })
    }, [rowsValue]);

    const onChangeRows = (data) => {
        const value = data.map(raw=>raw.inputs.value).join(",");
        setRowsValue(value);
    }

    return <DynamicRows
        title="Expected Values"
        className="pt-2"
        removeButtonClassName="none"
        rowsData={rowsData}
        onChange={onChangeRows}
        showTopDivider={false}
        showBottomDivider={false}
        rowsTemplate={(valuesMap, handleInputChange) => (
            <>
                <TextBox
                    rootClass="w-full pr-2 "
                    label=""
                    type="text"
                    name="value"
                    placeholder="Enter value"
                    value={valuesMap.value}
                    onChange={handleInputChange}
                />
            </>
        )}
    />
}