import React from "react";

export function RadioButton({name, label, selectedValue, value, onChange}) {
    console.log("RadioButton value", value)
    console.log("RadioButton selectedValue", selectedValue)
    return (
        <div className="flex items-center">
            <input
                onChange={onChange}
                id={name}
                type="radio"
                value={value}
                name={name}
                checked={selectedValue === value}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            <label htmlFor={name}
                   className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
        </div>
    )
}

export function RadioButtonGroup({name, options=[], selectedValue, onChange, direction='row'}) {
    let directionCss = direction === 'row'? 'flex-row': 'flex-col'
    return (
        <div className={`flex ${directionCss} gap-2`}>
            {options.map((option)=><RadioButton {...option} name={name} selectedValue={selectedValue} onChange={onChange}/>)}
        </div>
    )
}