import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { CgSpinner } from "react-icons/cg";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  Position,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";

import {
  journeyDefinitionSave,
  journeyGet, journeySchemaDownload,
} from "../../../../../services/journey/journey-service";

import Settings from "../../settings";
import { DrawerPanel } from "../../ui/drawer";
import CustomEdge from "../../common/components/journey/edge";
import journeyStmStore from "../../common/service/journey-stm-store";
import { useAppInfo } from "../../../../../helpers/hooks/common-hook";
import { useEventBus } from "../../../../../helpers/hooks/event-bus";
import {
  stmNodeSelect,
  toolboxItemAdd,
} from "../../../../../redux/journey-toolbox/journey-toolbox-actions";
import { nodeTypes } from "../../node-types/node-types-registry";
import { CHOICE_DEFAULT_RULE_NAME } from "../../common/const/settings-type";
import { CHOICE_NODE_TYPE } from "../../common/const/node-types";
import ChoiceRuleAddConfirm from "../../node-types/types/choice-rule-add-confirm-page";
import Message from "../../../../../components/toast-message";
import {getSettingsData} from "../../../../client-settings/client-settings-data";
import axios from "axios";
import config from "../../../../../config";
import moment from "moment";
import FormLoader from "../../../../../components/form-loader/form-loader";

const edgeTypes = {
  customEdge: CustomEdge,
};

const createNode = (
  reactFlowInstance,
  id,
  type,
  nodeIndex,
  name,
  position,
  metadata = {}
) => {
  return {
    id,
    type,
    data: { id, type, nodeIndex, name, metadata },
    position: reactFlowInstance.screenToFlowPosition(position),
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  };
};

const JourneyFlow = forwardRef((props, ref) => {
  const { appId } = useAppInfo();
  const eventBus = useEventBus();

  const {
    journeyId,
    addNodeTypeAddMessage,
    stmNodeSelectMessage,
    stmEdgeAddMessage,
    toolboxItemAddHandle,
    stmNodeSelectHandle,
    onChangeFormState,
      workflowData={},
  } = props;

  const reactFlowWrapper = useRef(null);
  const reactFlowInstance = useReactFlow();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedEdge, setSelectedEdge] = useState();

  const [choiceParams, setChoiceParams] = useState();
  const [openChoiceRuleAddConfirm, setOpenChoiceRuleAddConfirm] =
    useState(false);

  // const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [openStepSettings, setOpenStepSettings] = useState(false);

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(Object.assign({}, { processing, success, failed, message }));
  };

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(
      Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  const handleAddEdge = (_stmEdgeAddMessage) => {
    let newEdges = [...edges];
    if (_stmEdgeAddMessage && _stmEdgeAddMessage.singleConnection) {
      // remove other connections
      newEdges = newEdges.filter(
        (_edge) =>
          CHOICE_DEFAULT_RULE_NAME !== _edge.data.ruleName ||
          _edge.source !== _stmEdgeAddMessage.newEdge.source
      );
    }

    if (_stmEdgeAddMessage && _stmEdgeAddMessage.newEdge) {
      const notExists =
        newEdges.filter((id) => id === _stmEdgeAddMessage.newEdge.id).length ===
        0;
      if (notExists) {
        // add only not found
        newEdges.push(_stmEdgeAddMessage.newEdge);
      }
    }

    setEdges(newEdges);
  };

  useEffect(() => {
    //reactFlowInstance.setCenter(0, 0, { zoom: 0.0, duration: 1000 });
    const onSTMEdgeUpdate = (message) => {
      handleAddEdge(message);
    };

    eventBus.on("onSTMEdgeUpdate", onSTMEdgeUpdate);

    eventBus.on("onJourneySave", saveJourney);
  }, []);

  const fetchJourneyData = async () => {
    try {
      updateDataLoadingState(true, false, false);
      const savedJourney = workflowData
      console.log('savedJourney', savedJourney)
      const workflow = savedJourney.workflow;
      if (
          workflow
      ) {
        let {
          nodes,
          edges,
          journeyStmStore: journeyStmStoreData,
        } = savedJourney.metadata;
        journeyStmStore.setData(journeyStmStoreData);

        // prepare nodes
        nodes = nodes.map((node) => {
          const data = {
            ...node.data,
            metadata: { slots: savedJourney.slots },
          };
          return { ...node, data };
        });

        setNodes(nodes);
        setEdges(edges);
      } else {
        setNodes([]);
        setEdges([]);
      }

      if (savedJourney.slots) {
        setSlots(savedJourney.slots);
      }

      if (savedJourney.name) {
        eventBus.emit('onLoadJourneyData', savedJourney.name)
      }

      updateDataLoadingState(false, true, false);
    } catch (error) {
      console.error(error);
      updateDataLoadingState(false, false, true);
    }
  };

  useEffect(() => {
    if (journeyId === undefined) return;

    // fetchJourneyData();
  }, [journeyId]);

  useEffect(() => {
    if (stmNodeSelectMessage) {
      setSelectedNode(stmNodeSelectMessage.id);
      setSelectedEdge(stmNodeSelectMessage.edgeId);
      if (stmNodeSelectMessage.type) {
        setOpenStepSettings(true);
      }
    }
  }, [stmNodeSelectMessage]);

  useEffect(() => {
    if (selectedNode) {
      setNodes((nds) =>
        nds.map((node) => {
          let selected = false;

          if (node.id === selectedNode) {
            selected = true;
          }

          node.data = {
            ...node.data,
            selected,
          };
          return node;
        })
      );
    }
  }, [selectedNode]);

  useEffect(() => {
    if (selectedEdge) {
      setEdges((edges) =>
        edges.map((edge) => {
          let selected = false;

          if (edge.id === selectedEdge) {
            selected = true;
          }

          edge = {
            ...edge,
            selected,
          };
          return edge;
        })
      );
    }
  }, [selectedEdge]);

  useEffect(() => {
    if (addNodeTypeAddMessage && addNodeTypeAddMessage.type) {
      const node = createNode(
        reactFlowInstance,
        addNodeTypeAddMessage.id,
        addNodeTypeAddMessage.type,
        reactFlowInstance.getNodes().length + 1,
        addNodeTypeAddMessage.name,
        addNodeTypeAddMessage.position,
        { journeyId, slots }
      );
      reactFlowInstance.addNodes(node);
    }
  }, [addNodeTypeAddMessage]);

  useEffect(() => {
    handleAddEdge(stmEdgeAddMessage);
  }, [stmEdgeAddMessage]);

  const prepareStmStateData = (nodeSetting, next) => {
    const { type, stateConfig } = nodeSetting;
    const resp = { type, next };
    if (type === CHOICE_NODE_TYPE) {
      resp.stateConfig = {
        choices: Object.values(stateConfig.rules).map((r) => r.choice),
        defaultState: stateConfig.defaultState,
      };
    } else {
      resp.stateConfig = stateConfig;
    }
    return resp;
  };

  const setEndNodes = (nodesMap = {}, states = {}) => {
    const statesKeys = Object.keys(states);
    for (const key of Object.keys(nodesMap)) {
      if (!statesKeys.includes(key)) {
        const { type, stateConfig } = nodesMap[key];
        states[key] = { type, stateConfig, next: undefined };
      }
    }
  };

  const indexData = async (workflow) => {
    try {
      const settingsData = getSettingsData()
      const form = new FormData();
      form.append('client_id', settingsData.client_id);
      form.append('chat_bot', settingsData.chat_bot);
      form.append('language', settingsData.language);
      form.append('stage', settingsData.stage);
      form.append('topic', workflowData.topic);
      form.append('content', workflowData.content);
      form.append('workflow', JSON.stringify(workflow));
      await axios.post(`${config.API_WF_INGRESS_URL}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${settingsData.accessToken}`,
        },
      })
      return true;
    } catch (e) {
      if (e.response && e.response.status === 401) {
        window.location = config.LOGIN_URL
      }
      console.error('Workflow ingress failed', e)
      return false
    }
  }
  const saveJourney = async () => {
    try {
      console.log('!!!!! saveJourney edges', reactFlowInstance.getEdges())
      const settingsNotFoundErrorMessages = [];
      const nodes = reactFlowInstance.getNodes();

      const nodesMap = nodes.reduce((map, currentVal) => {
        const nodeSetting = journeyStmStore.get(currentVal.id);
        if (nodeSetting) {
          map[currentVal.id] = nodeSetting;
        } else {
          settingsNotFoundErrorMessages.push(currentVal.id);
        }
        return map;
      }, {});

      if (settingsNotFoundErrorMessages.length > 0) {
        throw new Error(
          `Invalid settings ${settingsNotFoundErrorMessages.join(",")}`
        );
      }

      const allBecomeSource = [];
      const allBecomeTarget = [];
      const states = {};
      for (const edge of reactFlowInstance.getEdges()) {
        const { source, target } = edge;
        states[source] = prepareStmStateData(nodesMap[source], target);
        allBecomeSource.push(source);
        allBecomeTarget.push(target);
      }

      const notBecomeSource = [];
      const notBecomeTarget = [];
      for (const node of nodes) {
        if (!allBecomeSource.includes(node.id)) {
          notBecomeSource.push(node.id);
        }

        if (!allBecomeTarget.includes(node.id)) {
          notBecomeTarget.push(node.id);
        }
      }

      setEndNodes(nodesMap, states);

      const firstNode = notBecomeTarget[0];
      const lastNode = notBecomeSource[0];

      states[lastNode] = prepareStmStateData(nodesMap[lastNode]);

      const stmDefinition = {
        startAt: firstNode,
        states: states,
      };

      console.log("stmDefinition", stmDefinition);

      if (!formState.processing) {
        // updateFormState(true, false, false);

        // prepare journeyStore for save. otherwise, rules not save
        const journeyStmStorePrep = Object.keys(
          journeyStmStore.getAll()
        ).reduce((map, key) => {
          const storeEntry = journeyStmStore.get(key);
          if (CHOICE_NODE_TYPE === storeEntry.type) {
            map[key] = storeEntry;
            map[key].stateConfig.rules = Object.keys(
              storeEntry.stateConfig.rules
            ).reduce((ruleMap, currentRuleKey) => {
              ruleMap[currentRuleKey] =
                storeEntry.stateConfig.rules[currentRuleKey];
              return ruleMap;
            }, {});
          } else {
            map[key] = storeEntry;
          }
          return map;
        }, {});
        console.log('reactFlowInstance.getEdges()', reactFlowInstance.getEdges())

        updateFormState(true, false, false);
        const metadata = {
          nodes,
          edges: reactFlowInstance.getEdges(),
          journeyStmStore: journeyStmStorePrep,
        }
        // TODO later remove
        stmDefinition.content = workflowData.content
        stmDefinition.metadata = metadata
        stmDefinition.modifiedAt =  moment.utc().format()
        // TODO later remove end

        const workflowSavingData = {
          topic: workflowData.topic,
          content: workflowData.content,
          workflow: stmDefinition,
          metadata: metadata
        }
        const indexStatus = await indexData(workflowSavingData.workflow)
        if (!indexStatus) {
          alert('Workflow ingress failed')
          updateFormState(false, false, true);
        } else {
          updateFormState(false, true, false);
        }

        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(workflowSavingData, null, 2));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "data.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
    } catch (e) {
      console.error("state definition failed", e);
      // updateFormState(false, false, true, e.message);
    }
  };

  useImperativeHandle(ref, () => ({
    onclickSaveHandler() {
      saveJourney();
    },
    fetchJourneyDataHandler() {
      fetchJourneyData();
    },
  }));

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onNodesDelete = (changes) => {
    journeyStmStore.remove(changes[0].id);
  };

  const onEdgesDelete = (changes) => {
    console.log("onEdgesDelete", changes);
  };

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const getEdgeData = (params, data = {}) => {
    return {
      ...params,
      data,
      type: "customEdge",
      markerEnd: { type: MarkerType.ArrowClosed },
    };
  };

  const updateCustomEdgeData = (params, data = {}) => {
    setEdges((eds) => addEdge(getEdgeData(params, data), eds));
  };

  const onConnect = (params) => {
    const sourceNode = reactFlowInstance.getNode(params.source);

    if (sourceNode.type === "choice") {
      setChoiceParams(params);
      setOpenChoiceRuleAddConfirm(true);
    } else {
      updateCustomEdgeData(params);
    }
  };

  const closeSettingPage = () => {
    setOpenStepSettings(false)
  }

  const onNodeClick = (_, node) => {
    setSelectedNode(node.id);
    stmNodeSelectHandle({
      id: node.id,
      type: node.data.type,
      nodeIndex: node.data.nodeIndex,
      name: node.data.name,
    });
  };

  const handleOnCompletionAddChoiceRule = async (ruleName) => {
    if (ruleName) {
      updateCustomEdgeData(choiceParams, { ruleName });
    }
    setOpenChoiceRuleAddConfirm(false);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = {
        x: event.clientX,
        y: event.clientY,
      };

      toolboxItemAddHandle({
        type,
        name: `New Step ${nodes.length + 1}`,
        position,
      });
    },
    [reactFlowInstance]
  );

  if (dataLoadingState.processing) {
    return (
      <div className="flex flex-grow items-center justify-center p-3">
        <div className="flex items-center gap-1 text-gray-500">
          <CgSpinner size={24} className="animate-spin" />
          Loading..
        </div>
      </div>
    );
  }

  if (dataLoadingState.failed) {
    return (
      <Alert color="failure" icon={HiInformationCircle}>
        <span className="font-medium">Error!</span> We're sorry, but we couldn't
        load the selected journey.
      </Alert>
    );
  }

  return (
    <div className="h-screen">
      {formState.success &&
          <Alert color="success" onDismiss={() => alert('Alert dismissed!')}>
            <span className="font-medium">Success!</span> Workflow successfully saved
          </Alert>
      }

      {formState.processing &&
          <div className="p-4"><FormLoader text="Saving"/></div>
      }

      <DrawerPanel
        open={openStepSettings}
        onClose={() => setOpenStepSettings(false)}
      >
        <Settings
          selectedNode={stmNodeSelectMessage}
          metadata={{ journeyId, slots }}
          closeSettingPage={closeSettingPage}
          onUpdate={()=>{}}
        />
      </DrawerPanel>

      {openChoiceRuleAddConfirm &&
          <ChoiceRuleAddConfirm
              open={openChoiceRuleAddConfirm}
              onCompletion={handleOnCompletionAddChoiceRule}
          />
      }
      <div className="flex-grow h-full" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodesDelete={onNodesDelete}
          onEdgesDelete={onEdgesDelete}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeClick={onNodeClick}
          onDragOver={onDragOver}
          onDrop={onDrop}
          // onInit={setReactFlowInstance}
          snapToGrid={true}
          snapGrid={[10, 10]}
          fitView
        >
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </div>
  );
});

JourneyFlow.propTypes = {
  toolboxItemAddHandle: PropTypes.func,
  stmNodeSelectHandle: PropTypes.func,
  onChangeFormState: PropTypes.func,
  addNodeTypeAddMessage: PropTypes.object,
  stmNodeSelectMessage: PropTypes.object,
  stmEdgeAddMessage: PropTypes.object,
};

const mapsStateToProps = (state, ownProps) => {
  return {
    journeyToolboxItemAddMessage: state.journeyToolboxItemAddMessage,
    stmNodeSelectMessage: state.stmNodeSelectMessage,
    stmEdgeAddMessage: state.stmEdgeAddMessage
  };
}

const mapDispatchToProps = dispatch => {
  return {
    toolboxItemAddHandle: (data) => {
      dispatch(toolboxItemAdd(data));
    },
    stmNodeSelectHandle: (message) => {
      dispatch(stmNodeSelect(message));
    },
  };
}

export default connect(mapsStateToProps, mapDispatchToProps, null, { forwardRef: true })(JourneyFlow)
