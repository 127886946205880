import {CommonNode} from "./types/common-node";
import {QuestionPromptNode} from "./types/question-prompt-node";
import {MessagePromptNode} from "./types/message-prompt-node";
import {WebhookActionNode} from "./types/webhook-action-node";
import {ChoiceNode} from "./types/choice-node";
import {
    CHOICE_NODE_TYPE,
    COMMON_NODE_TYPE,
    MESSAGE_PROMPT_NODE_TYPE,
    QUESTION_PROMPT_NODE_TYPE,
    WEBHOOK_ACTION_NODE_TYPE,
    WEBHOOK_PROMPT_NODE_TYPE
} from "../common/const/node-types";
import {WebhookPromptNode} from "./types/webhook-prompt-node";

const nodeTypes = { };
nodeTypes[COMMON_NODE_TYPE] = CommonNode
nodeTypes[QUESTION_PROMPT_NODE_TYPE] = QuestionPromptNode
nodeTypes[MESSAGE_PROMPT_NODE_TYPE] = MessagePromptNode
nodeTypes[WEBHOOK_PROMPT_NODE_TYPE] = WebhookPromptNode
nodeTypes[WEBHOOK_ACTION_NODE_TYPE] = WebhookActionNode
nodeTypes[CHOICE_NODE_TYPE] = ChoiceNode

export {
    nodeTypes
}