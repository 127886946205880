import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../../../../../components/button/button';

export default function Index({ appId, open, onCompletion }) {
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        name: ''
    });

    const [error, setError] = useState({
        name: false
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'name') {
            formData.name = targetValue;
        }

        setFormData(Object.assign({}, formData));
    });

    const validateForm = () => {
        let valid = true;
        if (formData.name) {
            error.name = false;
        } else {
            error.name = true;
            valid = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }



    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setIsOpen(false);
            onCompletion(formData.name);
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCompletion();
    });


    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={handleOnCancel}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <form onSubmit={handleOnSubmit} className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex items-start justify-center">
                                    <div className="w-full">
                                        <Dialog.Title as="h3" className="text-center text-2xl text-gray-600 font-bold">
                                            New Rule
                                        </Dialog.Title>
                                        <div>
                                            <div>
                                                <label className="text-gray-800 font-semibold block my-3 text-md" for="name">Name</label>
                                                <input
                                                    className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Rule name"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gap-2 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <>
                                    <Button variant="button" type="submit" text="Add" />
                                    <Button variant="button" color="default" text="Cancel" onClick={handleOnCancel} />
                                </>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}