import { Fragment, useRef, useState, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Modal } from 'flowbite-react';
import {HiOutlineExclamationCircle} from "react-icons/hi";

function ConfirmDialog({ open, title="Confirm", message, onCompletion }) {
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const handleOnYes = () => {
        setIsOpen(false);
        onCompletion(true);
    };

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCompletion();
    });

    return (
        <Modal show={open} size="md" popup onClose={handleOnCancel}>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {message}
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button color="gray" onClick={handleOnCancel}>
                            No, cancel
                        </Button>
                        <Button color="failure" onClick={handleOnYes}>
                            Yes, I'm sure
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

    // return (
    //     <Transition.Root show={isOpen} as={Fragment}>
    //         <Dialog
    //             as="div"
    //             className="fixed z-10 inset-0 overflow-y-auto"
    //             initialFocus={cancelButtonRef}
    //             onClose={handleOnCancel}
    //         >
    //             <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    //                 <Transition.Child
    //                     as={Fragment}
    //                     enter="ease-out duration-300"
    //                     enterFrom="opacity-0"
    //                     enterTo="opacity-100"
    //                     leave="ease-in duration-200"
    //                     leaveFrom="opacity-100"
    //                     leaveTo="opacity-0"
    //                 >
    //                     <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    //                 </Transition.Child>
    //
    //                 {/* This element is to trick the browser into centering the modal contents. */}
    //                 <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
    //                     &#8203;
    //                 </span>
    //                 <Transition.Child
    //                     as={Fragment}
    //                     enter="ease-out duration-300"
    //                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //                     enterTo="opacity-100 translate-y-0 sm:scale-100"
    //                     leave="ease-in duration-200"
    //                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    //                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //                 >
    //                     <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
    //                         <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    //                             <div className="flex items-start justify-center">
    //                                 <div className="w-full">
    //                                     <Dialog.Title as="h3" className="text-center text-xl text-gray-600 font-bold">
    //                                         {title}
    //                                     </Dialog.Title>
    //                                     <div>
    //                                         <p className="text-center text-md text-gray-500 font-bold pt-2">
    //                                             {message}
    //                                         </p>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="gap-2 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
    //                             <Button variant="button" text="Yes" onClick={handleOnYes} />
    //                             <Button variant="button" color="default" text="Cancel" onClick={handleOnCancel} />
    //                         </div>
    //                     </div>
    //                 </Transition.Child>
    //             </div>
    //         </Dialog>
    //     </Transition.Root>
    // )
}

export default ConfirmDialog