import {
    MdOutlineInsertChart,
    MdOutlineIntegrationInstructions,
} from "react-icons/md";
import {PiBookOpen} from "react-icons/pi";
import {CgProfile} from "react-icons/cg";
import {FaWhatsapp} from "react-icons/fa";
import {BsCodeSlash} from "react-icons/bs";
import {GoShareAndroid} from "react-icons/go";
import {AiOutlineSetting} from "react-icons/ai";
import {HiOutlineHashtag} from "react-icons/hi";
import {LuCodesandbox, LuMessageSquare} from "react-icons/lu";
import {IoChatboxEllipsesOutline, IoCube} from "react-icons/io5";
import {TbCash, TbDatabaseEdit, TbFolderHeart} from "react-icons/tb";

export const AGENTS_APP_INDEX = 1000;

const userProfileEndpoint = {
    name: "User Profile",
    href: "/user-settings/profile",
    active: true,
    icon: <CgProfile/>,
};

const billingEndpoint = {
    name: "Subscription",
    href: "/user-settings/subscription",
    active: false,
    icon: <TbCash/>,
};

const applicationEndpoint = {
    name: "Assistants",
    href: "/",
    active: true,
    icon: <IoCube/>,
};

export const userSettingsNavigation = [userProfileEndpoint, billingEndpoint];

export const appsNavigation = [{
    name: "Workflows",
    href: "/",
    active: false,
    icon: HiOutlineHashtag,
    tip: "User Intents",
},
];

// default sidebar paths
export const appNavigation = [
    {
        name: "Intents",
        href: "/journeys",
        active: false,
        icon: HiOutlineHashtag,
        tip: "User Intents",
    },
    // {
    //   name: "Documents",
    //   href: "/journeys",
    //   active: false,
    //   icon: PiBookOpen,
    //   tip: "User journeys",
    // },
    // {
    //   name: "Chat",
    //   href: "/journeys",
    //   active: false,
    //   icon: IoChatboxEllipsesOutline,
    //   tip: "User journeys",
    // },
    {
        name: "Applications",
        href: "/",
        active: false,
        icon: LuCodesandbox,
        tip: "User journeys",
        root: true
    },
];

// bottom of the sidebar paths
export const appNavigationEnd = [
    {
        name: "Agents",
        href: "/agents",
        active: false,
        icon: LuCodesandbox,
        tip: "Set up your agent's basic details",
    },
];
