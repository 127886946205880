export function appCreateNotifyMessage(
    state = {
        appId: null,
    }, action){

    switch(action.type){
        case 'APP_CREATE_NOTIFY':{
            return Object.assign({}, state, action.payload);
        }
        default:{
            return state;
        }
    }
}

export function appInfoMessage(
    state = {
        data: null
    }, action){

    switch(action.type){
        case 'APP_INFO':{
            return Object.assign({}, state, action.payload);
        }
        default:{
            return state;
        }
    }
}