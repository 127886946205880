import { AiOutlineRobot, AiOutlineThunderbolt } from "react-icons/ai";
import {
  MdAltRoute,
  MdConnectWithoutContact,
  MdOutlineAdsClick,
  MdOutlineCodeOff,
  MdOutlineSecurity,
  MdOutlineSupportAgent,
  MdPause
} from "react-icons/md";
import {PiTextTBold} from "react-icons/pi"
import {
  RiImageAddLine,
  RiMessage2Line,
  RiScreenshot2Fill,
} from "react-icons/ri";
import { MdUploadFile } from "react-icons/md";
import { TiDropbox } from "react-icons/ti";
import { TbArrowGuide, TbVariable, TbMessage2Pause } from "react-icons/tb";
import { LuBrainCircuit, LuPenTool, LuWorkflow } from "react-icons/lu";
import { BsStack } from "react-icons/bs";
import { HiBookOpen } from "react-icons/hi";

import {
  QUESTION_PROMPT_NODE_TYPE,
  BUTTONS_PROMPT_NODE_TYPE,
  AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE,
  CODEHOOK_PROMPT_NODE_TYPE,
    WEBHOOK_PROMPT_NODE_TYPE,
  CARDS_PROMPT_NODE_TYPE,
  IMAGE_PROMPT_NODE_TYPE,
  MESSAGE_PROMPT_NODE_TYPE,
  DOC_SEARCH_ACTION_NODE_TYPE,
  AI_TASK_ACTION_NODE_TYPE,
  LIVE_AGENT_ACTION_NODE_TYPE,
  JOURNEY_ACTION_NODE_TYPE,
  PAUSE_ACTION_NODE_TYPE,
  WEBHOOK_ACTION_NODE_TYPE,
  CODEHOOK_ACTION_NODE_TYPE,
  AUTH_NODE_TYPE,
  CHOICE_NODE_TYPE,
} from "../common/const/node-types";

export const toolboxFlowsTriggerButtons = [
  {
    id: "capture",
    title: "Capture",
    icon: MdUploadFile,
    nodes: [
      { id: QUESTION_PROMPT_NODE_TYPE, text: "Text", icon: PiTextTBold },
      {
        id: WEBHOOK_PROMPT_NODE_TYPE,
        text: "Webhook",
        icon: HiBookOpen
      }
    ],
  },
  {
    id: "response",
    title: "Response",
    icon: RiMessage2Line,
    nodes: [
      { id: MESSAGE_PROMPT_NODE_TYPE, text: "Text", icon: PiTextTBold },
      { id: IMAGE_PROMPT_NODE_TYPE, text: "Image", icon: RiImageAddLine },
    ],
  },
  {
    id: "dev",
    title: "Dev",
    icon: MdOutlineCodeOff,
    nodes: [
      { id: WEBHOOK_ACTION_NODE_TYPE, text: "Webhook", icon: HiBookOpen },
    ],
  },

  {
    id: "logic",
    title: "Logic",
    icon: TbArrowGuide,
    nodes: [
      { id: CHOICE_NODE_TYPE, text: "Condition", icon: MdAltRoute },
      // { id: CHOICE_NODE_TYPE, text: "Fallback", icon: TiDropbox },
    ],
  },
  // {
  //   id: "variable",
  //   title: "Variable",
  //   icon: TbVariable,
  //   event: "variable",
  //   nodes: [],
  // },
];
