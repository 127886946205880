const defaultSettings = {
    // "client_id": "HGC",
    // "chat_bot": "Helpdesk",
    // "language": "eng",
    // "stage": "UAT",
    "url": "http://165.227.249.157:8020/bead8b7d-2e07-46dc-bfe8-9e9a9dfd36cb",
    "token": ""
}

const settings_cache_key = 'DEFAI_CLIENT_SETTINGS_KEY'

function getSettingsData() {
    const savedSettings = localStorage.getItem(settings_cache_key)
    if (savedSettings) {
        return JSON.parse(savedSettings)
    } else {
        return null
    }
}

function setSettingsData(settings) {
    localStorage.setItem(settings_cache_key, JSON.stringify(settings))
}

function resetSettingsData() {
    localStorage.clear()
}


export {
    setSettingsData,
    getSettingsData,
    resetSettingsData
}