import { Label, Select } from 'flowbite-react';

export default function SelectInput(props) {
    return (
        <div
            className={props.rootClass || "max-w-md"}
            id={props.id}
        >
            <div className="mb-2 block">
                <Label
                    className={props.labelClass}
                    htmlFor={props.id}
                    value={props.label}
                />
            </div>

            <select
                {...props}
                id={props.id}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
                {props.options &&
                    <>
                        {props.options.map((data, index)=>
                            <option key={index} value={data.value}>
                                {data.label}
                            </option>
                        )}
                    </>
                }

                {props.optionsComp &&
                    <>
                        {props.optionsComp}
                    </>
                }
            </select>

            {props.error &&
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className="font-medium">
                        {props.errorMessage || "Please fill out this field"}
                    </span>
                </p>
            }
        </div>
    )
}


