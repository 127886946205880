import { combineReducers } from 'redux';
import { appCreateNotifyMessage, appInfoMessage } from './app/app-reducers';
import { journeyToolboxItemAddMessage, stmNodeSelectMessage, stmSettingSaveMessage, stmEdgeAddMessage,
  stmSlotCreateRequestMessage } from './journey-toolbox/journey-toolbox-reducers';

const staticReducers = {
  appCreateNotifyMessage,
  appInfoMessage,
  journeyToolboxItemAddMessage,
  stmNodeSelectMessage,
  stmSettingSaveMessage,
  stmEdgeAddMessage,
  stmSlotCreateRequestMessage
};

export default function createReducer(asyncReducers) {
  const reducers = {
    ...staticReducers,
    ...asyncReducers
  };
  return combineReducers(reducers);
}
