class MemoryDB {
    constructor() {
        this.data = {
            topic: null,
            content: null,
            workflow: null,
            slots: {},
            metadata: {}
        }
    }

    setTopic(topic) {
        this.data.topic = topic
    }

    setContent(content) {
        this.data.content = content
    }

    setWorkflow(workflow) {
        this.data.workflow = workflow
    }

    setSlots(slots) {
        this.data.slots = slots
    }
}

const memoryDB = new MemoryDB()
export default memoryDB