import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appInfoSet } from '../../redux/app/app-actions';
import {useAppContext, useAppInfoFromUralParams} from '../../helpers/hooks/common-hook';
import {AppContext, getDefaultContext} from "../../context/app-context";
import {getByAppId} from "../../services/app/app-service";
import FormLoader from "../form-loader/form-loader";
import {Link} from "react-router-dom";

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        appInfoSetHandle: (appInfo) => {
            dispatch(appInfoSet(appInfo));
        },
    };
}
function Page({ title, content, headerAction, appInfoSetHandle }) {
    const currentAppContext = useAppContext();
    const { appId } = useAppInfoFromUralParams();
    const [ appInfo, setAppInfo] = useState();
    const [ appInfoLoading, setAppInfoLoading] = useState({ loading: false, err: null });

    const fetchAppInfo = async () => {
        setAppInfoLoading({ loading: true, err: false });
        const response = await getByAppId(appId);
        if (response) {
            setAppInfo(response);
            setAppInfoLoading({ loading: false });
        } else {
            setAppInfoLoading({ loading: false, err: true });
        }

    }

    useEffect(() => {
        if (appId) {
            fetchAppInfo();
        }
    }, [appId]);

    useEffect(() => {
        if (appInfo) {
            appInfoSetHandle(appInfo);
        }
    }, [appInfo]);

    return (
        <>
            {!appId || appInfo ? (
                <AppContext.Provider value={{ ...currentAppContext, appInfo }}>
                    <div className="h-full max-w-full mx-auto py-0">
                        {content}
                    </div>
                </AppContext.Provider>
            ) : (
                <>
                    {appInfoLoading.loading && (
                        <FormLoader fullScreen={true} text="Loading..." />
                    )}

                    {appInfoLoading.err && (
                        <main className="grid h-screen place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
                            <div className="text-center">
                                <p className="text-base font-semibold text-indigo-600">404</p>
                                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                    Application not found
                                </h1>
                                <p className="mt-6 text-base leading-7 text-gray-600">
                                    Sorry, we couldn’t find the application you’re looking for.
                                </p>
                                <div className="mt-10 flex items-center justify-center gap-x-6">
                                    <Link
                                        to="/"
                                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Go back home
                                    </Link>
                                </div>
                            </div>
                        </main>
                    )}
                </>
            )}
        </>
    );
}
export default connect(mapsStateToProps, mapDispatchToProps)(Page)