import React, { useContext } from "react";
import { useLocation, useParams } from "react-router";
import { AppContext } from "../../context/app-context";

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useAppInfoFromUralParams() {
    const { appId } = useParams();
    return { appId };
}

export function useAppContext() {
    return useContext(AppContext);
}

const defaultApp = {
    appId: 'app123'
}
export function useAppInfo() {
    // const context= useContext(AppContext);
    // return context.appInfo;
    return defaultApp
}

export function useSettingsState() {
    const context= useContext(AppContext);
    return context.settingsState;
}