import {useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import Button from '../../../components/button/button';
import {journeyCreate, journeyUpdate} from '../../../services/journey/journey-service';
import {appCreateNotify} from "../../../redux/app/app-actions";
import TextBox from "../../../components/forms/text-box";
import {Modal} from "flowbite-react";
import Message from "../../../components/toast-message";
import {useNavigate} from "react-router-dom";
import TextArea from "../../../components/forms/text-area";

const mapsStateToProps = (state, ownProps) => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        appCreateNotifyHandle: (appId) => {
            dispatch(appCreateNotify(appId));
        },
    };
}

function ApplicationCreate({onCompletion, appId, saveData, onCancel, appCreateNotifyHandle, selectedTemplateProp}) {
    const rootRef = useRef(null);
    const navigate = useNavigate();
    const [savedAppId, setSavedAppId] = useState();
    console.log('saveData', saveData)
    const [formData, setFormData] = useState(saveData || {
        application_id: appId,
        name: '',
        description: '',
        ai_description: '',
    });

    const [error, setError] = useState({
        name: false,
        description: false,
        ai_description: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    useEffect(() => {
        if (savedAppId) {
            setTimeout(() => {
                onCompletion(true)
            }, 1500)
        }
    }, [savedAppId]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        formData[targetName] = event.target.value;
        setFormData({...formData});
    });

    const validateForm = () => {
        let valid = true;
        if (!formData.name) {
            error.name = true;
            valid = false;
        } else {
            error.name = false;
        }

        if (!formData.description) {
            error.description = true;
            valid = false;
        } else {
            error.description = false;
        }

        if (!formData.ai_description) {
            error.ai_description = true;
            valid = false;
        } else {
            error.ai_description = false;
        }

        setError({...error})
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            let response;
            let status;
            if (formData.id) {
                const _resp = await journeyUpdate(formData.id, formData);
                response = _resp.response
                status = _resp.status
            } else {
                const _resp = await journeyCreate(formData);
                response = _resp.response
                status = _resp.status
            }

            if (status === 200 || status === 201) {
                console.log('response', response)
                setSavedAppId(response.id)
                appCreateNotifyHandle(response.id);
                updateFormState(false, true, false, "Successfully saved");
            } else {
                updateFormState(false, false, true, "Unable to create Journey. Please try again");
            }
        }
    };
    const btnText = saveData? 'Update Intent': 'Create Intent'
    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={onCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Create New Intent</h3>

                            {formState.success && <Message text="Successfully Created New Intent" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            <TextBox
                                type="text"
                                name="name"
                                id="name"
                                label="Name"
                                value={formData.name}
                                placeholder="Enter your intent name"
                                onChange={handleChange}
                                error={error.name}
                            />

                            <TextArea
                                type="text"
                                name="description"
                                id="description"
                                label="Description"
                                value={formData.description}
                                placeholder="Enter description"
                                onChange={handleChange}
                                rows={3}
                                error={error.description}
                            />

                            <TextArea
                                type="text"
                                name="ai_description"
                                id="ai_description"
                                label="AI Description"
                                value={formData.ai_description}
                                placeholder="Enter AI Description"
                                onChange={handleChange}
                                rows={3}
                                error={error.ai_description}
                            />

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={onCancel}/>
                                {formState.processing?
                                    <Button type="button" text={btnText} loading={true}/>
                                    :
                                    <Button type="submit" text={btnText}/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(ApplicationCreate)