import React, {useState, useEffect, useCallback, useRef} from "react";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import {Alert, Badge, Modal, Button} from "flowbite-react";
import {journeyGet, journeySlotSave} from "../../services/journey/journey-service";
import Loader from "../form-loader/form-loader";
import {SLOT_LABEL} from "../../consts/common-label";

function Page({appId, journeyId, handleOpenSlotCreate, handleSetEditData, open, onCancel}) {
    const [isOpen, setIsOpen] = useState(open);

    const [savedJourney, setSavedJourney] = useState({
        slots: {},
        contextConfig: {
            inputContexts: []
        }
    });

    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState(false);

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    async function fetchSavedJourney() {
        updateFormState(true, false, false)
        const savedJourney = await journeyGet(appId, journeyId);
        setSavedJourney(savedJourney);
        console.log("savedJourney", savedJourney)
        updateFormState(false, false, false)
    }

    useEffect(() => {
        fetchSavedJourney()
    }, []);

    const handleSelectDelete = id => {
        setDeletingData(id);
        setDeleteRevokeConfirmDialog(true);
    }

    const handleConfirmDelete = async confirmed => {
        if (confirmed) {
            const newSlots = {...savedJourney.slots}
            delete newSlots[deletingData]
            await handleDeleteSlot(newSlots);
        }
        setDeleteRevokeConfirmDialog(false);
        setDeletingData()
    }

    const handleDeleteSlot = async slots => {
        updateFormState(true, false, false);
        const {response, status} = await journeySlotSave(appId, savedJourney.journeyId, {slots});
        if (status === 200) {
            updateFormState(false, true, false);
            await fetchSavedJourney();
        } else {
            updateFormState(false, false, true, response);
        }
    }

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCancel();
    });

    const rootRef = useRef(null);

    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={handleOnCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">

                        <div className="pt-2 flex flex-row justify-between">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Variables</h3>
                            <div className="flex flex-row items-center">
                                <Button size="sm" color="gray" onClick={handleOpenSlotCreate}>Add Variable</Button>
                            </div>
                        </div>

                        <p className="text-sm text-gray-500">{SLOT_LABEL}</p>

                        {formState.processing && <div className="p-4"><Loader /></div>}

                        { (!formState.processing && (Object.keys(savedJourney.slots || {}).length === 0)) &&
                            <div className="pt-8">
                                <Alert
                                    color="warning"
                                    rounded
                                >
                                <span>
                                    <p>
                                        You don't have any variables
                                    </p>
                                </span>
                                </Alert>
                            </div>
                        }

                        <div className="col-span-6">
                            {openDeleteConfirmDialog &&
                                <ConfirmDialog
                                    key="context"
                                    open={openDeleteConfirmDialog}
                                    onCompletion={handleConfirmDelete}
                                    message="Are you sure Do you want to delete this Variable ?"
                                />
                            }

                            <div className="flex flex-wrap gap-2">
                                {Object.keys(savedJourney.slots || {}).map((slotKey, si) =>
                                    <Badge size="sm" key={si} color="success" className="cursor-pointer">
                                        <div className="flex flex-row items-center gap-2">
                                            <p className="underline" onClick={
                                                () => handleSetEditData({slot: slotKey, ...savedJourney.slots[slotKey]})}>
                                                {slotKey}
                                            </p>
                                            <svg onClick={() => handleSelectDelete(slotKey)}
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                 stroke="currentColor"
                                                 className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-900">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M6 18L18 6M6 6l12 12"/>
                                            </svg>
                                        </div>

                                    </Badge>
                                )}
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Page;