export const NOT = 'NOT'

export const OPERATORS = [
    'matches string',
]

export const OPERATORS_VALUE_TYPE = {
    'matches string': []
}

export const VALUE_NEED_OPERATORS = [
    'matches string',
]

export function getProcessOP(operator, valueType='') {
    const operators = [
        {
            "operator": "matches string",
            "valueType": "",
            "proceedOperator": "StringMatches"
        }
    ]
    return operators.find(op => op.operator === operator && op.valueType === valueType)
}