import { get, post, del } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

const SELECTED_APP_ID_KEY = 'app-1';

export async function getAllApps() {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}`);
        if (status !== 200) {
            return [];
        }
        console.log('response', response)
        return response;
    } catch (err) {
        console.debug('app get-all failed cause', err.message, err.code);
        return [];
    }
}

export async function getByAppId(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}`);
        if (status !== 200) {
            return null;
        }
        return response;
    } catch (err) {
        console.debug('app get-by-app-id failed cause', err.message, err.code);
        return null;
    }
}

export async function createApp(request) {
    try {
        return await post(`${APPS_ENDPOINT}`, request);
    } catch (err) {
        console.debug('app create failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function deleteApp(appId) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}`);
    } catch (err) {
        console.debug('app delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export function setSelectedApp(appId) {
    localStorage.setItem(SELECTED_APP_ID_KEY, appId);
}

export function unsetSelectedApp() {
    localStorage.removeItem(SELECTED_APP_ID_KEY);
}

export function getSelectedApp() {
    const s = localStorage.getItem(SELECTED_APP_ID_KEY);
    console.log("saved app", s);
    return localStorage.getItem(SELECTED_APP_ID_KEY);
}