import {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import Button from "../../../../../components/button/button";
import TextBox from "../../../../../components/forms/text-box";
import TextArea from "../../../../../components/forms/text-area";
import Message from "../../../../../components/toast-message";
import {useAppInfo} from "../../../../../helpers/hooks/common-hook";
import ActionParameters from "../../../../../components/action-parameter-row";
import FormLoader from "../../../../../components/form-loader/form-loader";
import memoryDB from "../../memory-db";

function JourneyCreateModel({onCompletion, onCancel, journeyId, topic, content}) {
    const rootRef = useRef(null);
    const navigate = useNavigate();
    const { appId } = useAppInfo();
    const [formData, setFormData] = useState({
        topic,
        content
    });

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [error, setError] = useState({
        topic: false,
        content: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newForm = {...formData}
        newForm[targetName] = targetValue
        setFormData(newForm);
    });

    const validateForm = () => {
        let valid = true;
        if (!formData.topic) {
            error.topic = true;
            valid = false;
        } else {
            error.topic = false;
        }

        if (!formData.content) {
            error.content = true;
            valid = false;
        } else {
            error.content = false;
        }
        setError({...error})
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoading(Object.assign({}, {processing, success, failed, message}))
    }

    async function saveAgentActionSettings(appId, formData) {
        
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            onCompletion(formData)
        }
    };

    async function getAgentAction(appId, journeyId) {
        
    }

    async function fetchActionSettings() {
        updateDataLoadingState(true, false, false);
        const resp = await getAgentAction(appId, journeyId);
        console.log("fetchChatBotSettings", resp)
        if (resp) {
            setFormData(resp);
        }
        updateDataLoadingState(false, true, false);
    }

    useEffect( () => {
        if (journeyId) {
            fetchActionSettings()
        }
    }, [journeyId]);

    const buttonText = "Set Topic";
    return (
        <div ref={rootRef}>
            <Modal show={true} size={journeyId? "4xl": "md"} popup onClose={onCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">

                            {formState.processing && <FormLoader relative={true}/>}

                            {formState.success && <Message text="Successfully Created New Journey" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            <TextBox
                                type="text"
                                name="topic"
                                id="topic"
                                label="Topic"
                                placeholder="Add Topic"
                                value={formData.topic}
                                onChange={handleChange}
                                error={error.topic}
                            />

                            <TextArea
                                rootClass="w-full"
                                label="Content"
                                type="text"
                                name="content"
                                placeholder="Add Content"
                                value={formData.content}
                                onChange={handleChange}
                                error={error.content}
                            />

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={onCancel}/>
                                {formState.processing?
                                    <Button type="button" text={buttonText} loading={true}/>
                                    :
                                    <Button type="submit" text={buttonText}/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default JourneyCreateModel