import {useCallback, useEffect, useRef, useState} from 'react';
import Button from '../../components/button/button';
import {journeyGet, journeySlotSave} from '../../services/journey/journey-service';
import Message from "../../components/toast-message";
import Loader from "../../components/form-loader/form-loader";
import {Modal} from "flowbite-react";
import TextBox from "../../components/forms/text-box";
import DropDown from "../forms/dropdown";
import {
    SLOT_DATA_TYPE_CAPTURE_BY_AI,
    SLOT_DATA_TYPE_REGEX, SLOT_DATA_TYPE_VALUES,
    SLOT_DATA_TYPES
} from "../../pages/journey/editor/common/const/slot-data-type";
import {TextAreaContent, ValuesContent} from "./slot-data-type-content";
import {Switch} from "@headlessui/react";
import {SLOT_LABEL} from "../../consts/common-label";
import memoryDB from "../../pages/journey/editor/memory-db";

function Page({ appId, journeyId, savedData={}, open, onCompletion }) {
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        slot: savedData.slot,
        dataType: savedData.dataType,
        content: savedData.content
    });

    const [journey, setJourney] = useState({
        slots: {},
    });

    const [error, setError] = useState({
        slot: false,
        dataType: false,
        content: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [dataLoadingFormState, setDataLoadingFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    async function fetchSavedJourney() {
        setJourney(memoryDB.data);
    }

    useEffect(() => {
        fetchSavedJourney()
    }, []);

    useEffect(() => {
        if (formState.success) {
            setTimeout(()=>{
                setIsOpen(false);
                onCompletion(true);
            }, 1500)
        }
    }, [formState.success]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        formData[targetName] = event.target.value;
        setFormData(Object.assign({}, formData));
    });

    const validateForm = () => {
        let valid = true;
        if (formData.slot) {
            error.slot = false;
        } else {
            error.slot = true;
            valid = false;
        }

        if (formData.dataType) {
            error.dataType = false;
        } else {
            error.dataType = true;
            valid = false;
        }

        setError({...error})
        return valid;
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const updateDataLoadingFormState = (processing, success, failed, errorMessage) => {
        setDataLoadingFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            updateFormState(true, false, false);
            const slots = journey.slots || {};
            slots[formData.slot] = {...formData};
            memoryDB.setSlots(slots)
            updateFormState(false, true, false);
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCompletion();
    });

    const rootRef = useRef(null);
    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={handleOnCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleOnSubmit} >
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">{savedData.slot? 'Edit Slot': 'New Slot'}</h3>

                            {formState.failed && <Message text={formState.errorMessage} type="failure" />}

                            {formState.success && <Message text="Successfully saved Slot" type="success" />}

                            {dataLoadingFormState.processing && <Loader fullScreen={true} />}
                            {dataLoadingFormState.failed && <Message text="Unable to load Slot" type="failure" />}

                            <p className="text-sm text-gray-500">{SLOT_LABEL}</p>

                            <TextBox
                                label="Variable"
                                type="text"
                                name="slot"
                                id="slot"
                                value={formData.slot}
                                placeholder="Enter Variable Name"
                                onChange={handleChange}
                                disabled={!!savedData.slot}
                                error={error.slot}
                            />

                            <DropDown
                                className=""
                                selectedOptionClassName="capitalize"
                                optionClassName="capitalize"
                                label="Data Type"
                                placeHolder="Not Set"
                                name="dataType"
                                value={formData.dataType}
                                options={SLOT_DATA_TYPES}
                                onChange={handleChange}
                                error={error.dataType}
                            />

                            {formData.dataType === SLOT_DATA_TYPE_CAPTURE_BY_AI &&
                                <TextAreaContent
                                    label="Description"
                                    placeholder="Describe the slot to the AI"
                                    content={formData.content}
                                    onChange={handleChange}
                                    error={error.content}
                                />
                            }

                            {formData.dataType === SLOT_DATA_TYPE_REGEX &&
                                <TextAreaContent
                                    label="Regex"
                                    placeholder="Enter regx"
                                    content={formData.content}
                                    onChange={handleChange}
                                    error={error.content}
                                />
                            }

                            {formData.dataType === SLOT_DATA_TYPE_VALUES &&
                                <ValuesContent
                                    content={formData.content}
                                    onChange={handleChange}
                                />
                            }

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={handleOnCancel}/>
                                {formState.processing?
                                    <Button type="button" text="Save Variable" loading={true}/>
                                :
                                    <Button type="submit" text="Save Variable"/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Page;