import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../dto/stm-state-dto";
import {QUESTION_PROMPT} from "../../const/settings-type";
import { Switch } from '@headlessui/react'
import SelectInput from "../../../../../../components/forms/select";
import TextBox from "../../../../../../components/forms/text-box";
import {connect} from "react-redux";
import {stmSlotCreateRequest} from "../../../../../../redux/journey-toolbox/journey-toolbox-actions";
import {SLOT_LABEL} from "../../../../../../consts/common-label";

const getAutoFillFromEntity = autoFillFromEntity => {
    return (autoFillFromEntity === undefined || autoFillFromEntity === null)? true: autoFillFromEntity;
}

function Page({value, autoFillFromEntity, slots={}, onChange, title= 'Variable', slotCreatRequest, showCaptureFromUserInput=true}) {
    const [valueSt, setValueSt] = useState(value || '');
    const [valueAutoFillFromEntity, setValueAutoFillFromEntity] = useState(getAutoFillFromEntity(autoFillFromEntity));

    useEffect(() => {
        setValueSt(value);
    }, [value]);

    useEffect(() => {
        setValueAutoFillFromEntity(getAutoFillFromEntity(autoFillFromEntity));
    }, [autoFillFromEntity]);

    const isEntityTypeSlot = () => {
        return !!(valueSt && slots[valueSt] && slots[valueSt].captureFromUserInput);
    }

    return (
        <div className="py-4 flex flex-col gap-4">
            <div className="flex flex-col">
                {/*<SelectInput*/}
                {/*    label={title}*/}
                {/*    id="slot"*/}
                {/*    name="slot"*/}
                {/*    autoComplete="slot"*/}
                {/*    value={valueSt}*/}
                {/*    onChange={event=>{*/}
                {/*        console.log("slot select", event.target.value)*/}
                {/*        onChange({target: {name: 'slot', value: event.target.value}})*/}
                {/*    }}*/}
                {/*    optionsComp={<>*/}
                {/*        {valueSt?*/}
                {/*            <option value="">Unselect Variable</option>*/}
                {/*        :*/}
                {/*            <option value="">Select Variable</option>*/}
                {/*        }*/}
                {/*        {Object.keys(slots).map((slotName, si)=>(*/}
                {/*            <option key={si} value={slotName}>{slotName}</option>*/}
                {/*        ))}*/}
                {/*    </>}*/}
                {/*/>*/}

                <TextBox
                    label={title}
                    id="slot"
                    name="slot"
                    autoComplete="slot"
                    value={valueSt}
                    onChange={event=>onChange({target: {name: 'slot', value: event.target.value}})}
                />

            </div>

            {/*<div className="flex flex-row justify-end">*/}
            {/*    <button onClick={slotCreatRequest} className="text-sm text-blue-700 font-bold underline">Create Variable</button>*/}
            {/*</div>*/}

            {/*{(showCaptureFromUserInput && isEntityTypeSlot()) &&*/}
            {/*    <div className="flex flex-row justify-between items-center">*/}
            {/*        <label htmlFor="slot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*/}
            {/*            Capture from user input*/}
            {/*        </label>*/}
            {/*        <Switch*/}
            {/*            checked={valueAutoFillFromEntity}*/}
            {/*            onChange={val=>onChange({target: {name: 'autoFillFromEntity', value: val}})}*/}
            {/*            className={`${*/}
            {/*                valueAutoFillFromEntity ? 'bg-blue-600' : 'bg-gray-200'*/}
            {/*            } relative inline-flex h-6 w-11 items-center rounded-full`}*/}
            {/*        >*/}
            {/*            <span className="sr-only">Auto Fill</span>*/}
            {/*            <span*/}
            {/*                className={`${*/}
            {/*                    valueAutoFillFromEntity ? 'translate-x-6' : 'translate-x-1'*/}
            {/*                } inline-block h-4 w-4 transform rounded-full bg-white transition`}*/}
            {/*            />*/}
            {/*        </Switch>*/}
            {/*    </div>*/}
            {/*}*/}

        </div>

    )
}
const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        slotCreatRequest: () => {
            dispatch(stmSlotCreateRequest());
        },
    };
}

export default connect(mapsStateToProps, mapDispatchToProps)(Page)