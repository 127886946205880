import {Switch} from "@headlessui/react";
import React from "react";

export default function PauseAfterExecutionSwitch({pauseAfterExecution, formData, setFormData}) {
    const pause = pauseAfterExecution;
    return (
        <div className="pt-4 flex flex-row justify-between items-center">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Pause After Execution
            </label>
            <Switch
                checked={pause}
                onChange={val=>setFormData({...formData, pauseAfterExecution: val})}
                className={`${
                    pause ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
                <span className="sr-only">Pause After Execution</span>
                <span
                    className={`${
                        pause ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
    )
}