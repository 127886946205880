import React from 'react';

// Creating a basic EventBus using React context
const EventBusContext = React.createContext(null);

export const EventBusProvider = ({ children }) => {
    const eventBus = {
        events: {},
        emit(event, data) {
            if (this.events[event]) {
                this.events[event].forEach(callback => callback(data));
            }
        },
        on(event, callback) {
            if (!this.events[event]) {
                this.events[event] = [];
            }
            this.events[event].push(callback);
        },
        off(event, callback) {
            if (this.events[event]) {
                const index = this.events[event].indexOf(callback);
                if (index > -1) {
                    this.events[event].splice(index, 1);
                }
            }
        },
    };

    return (
        <EventBusContext.Provider value={eventBus}>
            {children}
        </EventBusContext.Provider>
    );
};

export const useEventBus = () => React.useContext(EventBusContext);