import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { settingsTypes } from "./settings-types-registry";
import journeyStmStore from "../common/service/journey-stm-store";
import { stmSettingSave } from "../../../../redux/journey-toolbox/journey-toolbox-actions";

const getNodeData = (id) => {
  const storedSettings = journeyStmStore.get(id);
  if (storedSettings) {
    return storedSettings.stateConfig;
  } else {
    return null;
  }
};

const ChildNode = (props) => {
  return React.Children.map(props.children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    const childProps = {
      id: props.id,
      name: props.name,
      onChangeData: props.onChangeData,
      savedSettings: props.savedSettings,
      metadata: props.metadata,
      closeSettingPage: props.closeSettingPage
    };

    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }

    return child;
  });
};

function Settings({ selectedNode, metadata, stmSettingSaveHandle, onUpdate, closeSettingPage }) {
  const { id, type, name } = selectedNode;

  const savedSettings = getNodeData(id);

  let content;
  let title = "";

  const settingsType = settingsTypes[type];

  if (settingsType) {
    title = settingsType.title;
    content = settingsType.component;
  }

  const onChangeData = (data) => {
    journeyStmStore.add(id, data);
    stmSettingSaveHandle({ id, data });
    onUpdate()
  };

  return (
    <div className="p-0 m-1 box-border scrollbars h-[calc(100vh-4rem)] overflow-hidden overflow-y-auto">
      <div className="p-3 px-5 bg-white dark:bg-gray-800 sticky top-0 border-b border-t border-gray-200 dark:border-gray-700 z-10">
        <p className="text-lg font-semibold text-gray-900 dark:text-white">
          {title}
        </p>
      </div>
      {/* Setting options */}
      {content ? (
        <div className="p-5 block">
          <ChildNode
            id={id}
            name={name}
            metadata={metadata}
            savedSettings={savedSettings}
            onChangeData={onChangeData}
            closeSettingPage={closeSettingPage}
          >
            {content}
          </ChildNode>
        </div>
      ) : (
        <div
          className="
        h-[90%] text-sm text-gray-300 dark:text-gray-500 
        flex flex-1 flex-grow justify-center items-center"
        >
          No settings in {title}
        </div>
      )}
    </div>
  );
}

const mapsStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    stmSettingSaveHandle: (data) => {
      dispatch(stmSettingSave(data));
    },
  };
};

Settings.propTypes = {
  selectedNode: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    nodeIndex: PropTypes.number,
    type: PropTypes.string,
  }),
  metadata: PropTypes.shape({
    journeyId: PropTypes.string,
    slots: PropTypes.object,
  }),
};

export default connect(mapsStateToProps, mapDispatchToProps)(Settings);
