import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import {getSettingsData, setSettingsData} from "../client-settings/client-settings-data";
import { post } from "../../helpers/utils/http-client";
import { useQuery } from "../../helpers/hooks/common-hook";

const ChatInterface = () => {
    const query = useQuery();
    const url = query.get("url");
    const token = query.get("token");
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [sessionId, setSessionId] = useState(uuidv4());

    const messageContainerRef = useRef(null);

    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (url && token) {
            setSettingsData({
                url, token
            })
        }
    }, [url, token]);

    const handleSendMessage = async (event) => {
        event.preventDefault();
        if (input.trim()) {
            const userMessage = { text: input, type: 'text', sender: 'user', loading: false };
            setMessages([...messages, userMessage]);
            setInput('');

            try {
                const settingsData = getSettingsData()
                const { response } = await post(
                    url || settingsData.url,
                    {
                        "session_id": sessionId,
                        "input_text": input
                    }, {
                        'apiKey': token || settingsData.token
                    }
                );
                const newMessages = response.messages.map(({content, type}) =>
                    ({ text: content['text'], type: type, sender: 'bot' }))
                setMessages(prevMessages =>
                    prevMessages.concat(newMessages)
                );
            } catch (error) {
                console.error('Error sending message:', error);
                setMessages(prevMessages =>
                    prevMessages.concat({ text: 'Failed to send', type: 'text', sender: 'bot' })
                );
            }
        }
    };

    const handleClearSession = () => {
        setSessionId(uuidv4())
        setMessages([])
    };

    return (
        <div className="bg-white p-4 rounded shadow-md w-full h-full max-w-4xl max-h-[100vh] flex flex-col mx-auto">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-bold">Chatbot</h1>
                <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={handleClearSession}
                >
                    Clear Session
                </button>
            </div>
            <div ref={messageContainerRef} className="flex-grow overflow-y-auto mb-4">
                {messages.map((message, index) => (
                    <div key={index} className={`mb-4 ${message.sender === 'bot' ? 'pl-2' : 'pr-2'}`}>
                        <div className={`flex ${message.sender === 'bot' ? 'justify-start' : 'justify-end'}`}>
                            {message.sender === 'bot' && (
                                <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white mr-2">
                                    AI
                                </div>
                            )}
                            <div className={`max-w-[70%] p-3 rounded-lg ${message.sender === 'bot' ? 'bg-gray-100' : 'bg-blue-500 text-white'}`}>
                                <ReactMarkdown>{message.text}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <form className="flex" onSubmit={handleSendMessage}>
                <input
                    type="text"
                    placeholder="Type a message"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className="flex-grow p-2 border rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Send
                </button>
            </form>
        </div>
    );
};

export default ChatInterface;