import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";
import MessagePromptSettings from "../../settings/message-prompt-settings";

export function MessagePromptNode({ id, data }) {
    return (
        <NodeComponent title="Message" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <MessagePromptSettings/>
        </NodeComponent>
    );
}

