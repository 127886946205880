import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { cn } from "../../../../../helpers/utils/css-utils";

const DrawerPanel = React.forwardRef(({ children, open, onClose }, ref) => {
  const timeoutRef = useRef(null);
  const [isOpen, setIsOpen] = useState(open);
  const [shouldRender, setShouldRender] = useState(open);

  useEffect(() => {
    if (open) {
      setShouldRender(true);
      setTimeout(() => {
        setIsOpen(true);
      }, 150);
    } else {
      setIsOpen(false);
      timeoutRef.current = setTimeout(() => {
        setShouldRender(false);
      }, 300);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      className="bg-transparent fixed inset-0 w-full h-full z-50 overflow-hidden"
      onClick={onClose}
    >
      {/* body wrapper */}
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={cn(
          `fixed top-0 bottom-0 w-full z-[999] max-w-sm bg-white dark:bg-gray-800 shadow-xl transition-all duration-500 delay-[30ms] ease-in-out`,
          {
            "right-0": isOpen,
            "-right-full": !isOpen,
          }
        )}
      >
        <div className="relative flex-1 flex flex-col space-y-2">
          {/* header */}
          <div className="w-full flex items-center justify-end">
            <button
              onClick={onClose}
              className="p-1 m-2 mb-0 border border-transparent rounded-md 
              hover:border-gray-300 hover:text-gray-400 hover:bg-gray-50
              dark:hover:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              <MdClose size={17} />
            </button>
          </div>
          {/* content */}
          <>{children}</>
        </div>
        {/* content */}
      </div>
      {/* overlay */}
      <div
        className={cn(
          "fixed h-screen w-screen z-[998] inset-0 bg-gray-900 dark:bg-gray-900 dark:bg-opacity-80 bg-opacity-40 transition-all duration-75 ease-in-out",
          {
            "opacity-100": isOpen,
            "opacity-0": !isOpen,
          }
        )}
      />
    </div>
  );
});

export { DrawerPanel };
