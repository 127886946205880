import { format as fnsFormat, parseISO as fnsParseISO } from 'date-fns';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

export function parseAndFormat(date, format) {
  try {
    const parseDate = fnsParseISO(date);
    return fnsFormat(parseDate, format);
  } catch (err) {
    return date;
  }
}

export function fromNow(date) {
  return dayjs().from(dayjs(date))
}

export function format(date, format) {
  return fnsFormat(date, format);
}

export function parseISO(isoDate) {
  return fnsParseISO(isoDate);
}
