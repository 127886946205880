import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";
import QuestionPromptSettings from "../../settings/question-prompt-settings";

export function QuestionPromptNode({ id, data }) {
    return (
        <NodeComponent title="Question" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <QuestionPromptSettings/>
        </NodeComponent>
    );
}

