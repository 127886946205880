import {useRef, useState, useEffect, useCallback} from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { ReactFlowProvider } from "reactflow";
import {useAppInfo, useQuery} from "../../../helpers/hooks/common-hook";
import { stmNodeSelect } from "../../../redux/journey-toolbox/journey-toolbox-actions";

import PageContainer from "../../../components/page-container";
import JourneyList from "./journey/journey-list";
import JourneyFlow from "./journey/journey-flow";
import Toolbox from "./toolbox";

// Custome stylesheet
import "reactflow/dist/style.css";
import "./index.css";
import ContentHeader from "../../../components/page-content-header";
import TableReloadButton from "../../../components/button/table-reload-button";
import Button from "../../../components/button/button";
import {useEventBus} from "../../../helpers/hooks/event-bus";
import Message from "../../../components/toast-message";
import JourneyCreateDialog from "./journey/journey-create";
//import memoryDB from "./memory-db";
import {journeyList} from "../../../services/journey/journey-service";
import {CgSpinner} from "react-icons/cg";
import FormLoader from "../../../components/form-loader/form-loader";
import templateMap from "../../../assets/templates";

function Page({
  journeyToolboxItemAddMessage = {},
  stmNodeSelectMessage,
  stmEdgeAddMessage,
  stmNodeSelectHandle,
}) {
  const { appId } = useAppInfo();

  const eventBus = useEventBus();

  const query = useQuery();
  const q_topic = query.get("q_topic");
  const q_template = query.get("q_template");

  const [ formData, setFormData ] = useState({
    topic: '',
    content: '',
    workflow: null,
    metadata: null
  });
  const journeyEditorRef = useRef(null);
  const [saveState, setSaveState] = useState(false);
  const [journeyName, setJourneyName] = useState(false);
  const fileInputRef = useRef(null);

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(Object.assign({}, { processing, success, failed, message }));
  };

  async function fetchConfigs() {
    updateDataLoadingState(true, false, false);
    const data = await journeyList();
    if (data) {
      const savedFormData = JSON.parse(data[q_topic.toLowerCase()])
      console.log('savedFormData', savedFormData)
      savedFormData.content = savedFormData.workflow.content
      savedFormData.metadata = savedFormData.workflow.metadata
      setFormData(savedFormData)
      // memoryDB.setWorkflow(wf)
      // journeyEditorRef.current.fetchJourneyDataHandler();
    }
    updateDataLoadingState(false, true, false);
  }

  useEffect(() => {
    eventBus.on("onSuccessSave", ()=>{
      setSaveState(true)
      setTimeout(()=>{setSaveState(false)}, 3000)
    });

    eventBus.on("onLoadJourneyData", name=>{
      setJourneyName(name)
    });

    if (!formData.topic && !q_topic && !q_template) {
      setOpenJourneyCreate(true);
    }
  }, []);

  useEffect(() => {
    if (q_topic) {
      fetchConfigs()
    }
  }, [q_topic]);

  useEffect(() => {
    if (q_template ) {
      const template = templateMap[q_template]
      if (template) {
        setFormData({...template})
      }
    }
  }, [q_template]);

  const onClickSave = useCallback(() => {
    if (journeyEditorRef.current) {
      journeyEditorRef.current.onclickSaveHandler();
    }
  }, []);

  const [openJourneyCreate, setOpenJourneyCreate] = useState(false);
  const handleOnCancelJourneyCreate = () => {
    setOpenJourneyCreate(false);
  };

  const handleOnSuccessJourneyCreate = (data) => {
    setOpenJourneyCreate(false);
    console.log('setJourneyId')
    setFormData({...formData, ...data})
  }


  const handleButtonClickForFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = useCallback(event => {

    const file = event.target.files[0];
    const reader = new FileReader();
    console.log('FILE')
    reader.onload = (e) => {
      try {
        const savedDataFile = JSON.parse(e.target.result)
        setFormData(savedDataFile)
      } catch (error) {
        alert('Invalid JSON file');
      }
    };

    reader.readAsText(file);
  }, [])

  useEffect(() => {
    if (journeyEditorRef.current) {
      journeyEditorRef.current.fetchJourneyDataHandler();
    }
  }, [formData]);

  if (dataLoadingState.processing) {
    return (
        <FormLoader fullScreen={true} />
    );
  }

  return (
    <PageContainer
        title={`${formData.topic? `Topic : ${formData.topic}`: 'Topic not set'} `}
        headerAction={
      <div className="flex flex-row items-center gap-4">
        {saveState &&
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              Successfully Generated
            </span>
        }
        {openJourneyCreate && (
            <JourneyCreateDialog
                topic={formData.topic}
                content={formData.content}
                onCompletion={handleOnSuccessJourneyCreate}
                onCancel={handleOnCancelJourneyCreate}
            />
        )}
        {/*<TableReloadButton onClick={() => {}}/>*/}
        <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
        />
        <Button
            color="default"
            onClick={handleButtonClickForFile}
            text="Upload Schema"
        />
        <Button
            color="default"
            onClick={()=>setOpenJourneyCreate(true)}
            text="Edit Topic"
        />
        <Button
            variant="button"
            onClick={onClickSave}
            text="Save Workflow"/>
      </div>
    }>
      <div className="py-4 flex flex-1 gap-4 xl:gap-4">
        {/*<JourneyList />*/}
        <ReactFlowProvider>
          <div className="border dark:border-gray-700 rounded-lg flex flex-col flex-1">
            {/* Journey nodes toolbox */}
            <Toolbox appId={appId} journeyId={formData.topic} />
            {/* Journey flow editor */}
            <JourneyFlow
              ref={journeyEditorRef}
              journeyId={formData.topic}
              stmNodeSelectHandle={stmNodeSelectHandle}
              addNodeTypeAddMessage={journeyToolboxItemAddMessage}
              stmNodeSelectMessage={stmNodeSelectMessage}
              stmEdgeAddMessage={stmEdgeAddMessage}
              onChangeFormState={(state) => {}}
              attributionPosition="top-right"
              workflowData={formData}
            />
          </div>
        </ReactFlowProvider>
      </div>
    </PageContainer>
  );
}

const mapsStateToProps = (state, ownProps) => {
  return {
    journeyToolboxItemAddMessage: state.journeyToolboxItemAddMessage,
    stmNodeSelectMessage: state.stmNodeSelectMessage,
    stmEdgeAddMessage: state.stmEdgeAddMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stmNodeSelectHandle: (message) => {
      dispatch(stmNodeSelect(message));
    },
  };
};

export default connect(mapsStateToProps, mapDispatchToProps)(Page);
