import React from 'react';
import {
    getBezierPath, getBezierPath as getEdgeCenter, getMarkerEnd, MarkerType, useReactFlow, BaseEdge, EdgeLabelRenderer} from 'reactflow';
import './index.css';
import {CHOICE_NODE_TYPE} from "../../const/node-types";
import {connect} from "react-redux";
import {stmNodeSelect} from "../../../../../../redux/journey-toolbox/journey-toolbox-actions";
import {StmStateDto} from "../../dto/stm-state-dto";
import {CHOICE, CHOICE_DEFAULT_RULE_NAME} from "../../const/settings-type";
import journeyStmStore from "../../service/journey-stm-store";
const foreignObjectSize = 40;

export function ConnectionLine({
                            sourceX,
                            sourceY,
                            sourcePosition,
                            targetX,
                            targetY,
                            targetPosition,
                            connectionLineType,
                            connectionLineStyle,
                        }) {
    return (
        <g>
            <path
                fill="none"
                stroke="#222"
                strokeWidth={1.5}
                className="animated"
                d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
            />
            <circle cx={targetX} cy={targetY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
        </g>
    );
}

function ChoiceAction({
                            edgeId,
                            ruleName,
                            sourceNode,
                            targetNode, onActionHandle, selected
                               }) {
    const onClick = () => {
        onActionHandle({
            id: sourceNode.id,
            type: sourceNode.data.type,
            nodeIndex: sourceNode.data.nodeIndex,
            edgeId,
            ruleName
        })
    }
    const className = `text-xs bg-gray-200 border-gray-400 hover:bg-blue-100 hover:border-blue-400 px-2 border rounded ${selected?'bg-blue-100 border-blue-400':''}`
    return (
        <button className={className} onClick={onClick}>
            {ruleName} rule
        </button>
    )
}

const getEdgeAction = (reactFlowInstance, edgeId, source, target, selected, onActionHandle, data={}) => {
    const sourceNode = reactFlowInstance.getNode(source)
    const targetNode = reactFlowInstance.getNode(target)
    const { ruleName } = data;
    const { type } = sourceNode;
    if (CHOICE_NODE_TYPE === type) {
        if (CHOICE_DEFAULT_RULE_NAME !== ruleName) {
            const savedSettings = journeyStmStore.get(source) || new StmStateDto({
                type: CHOICE,
                stateConfig: { rules: {}, defaultState: '' },
            })
            savedSettings.stateConfig = savedSettings.stateConfig || { rules: {} };
            savedSettings.stateConfig.rules = savedSettings.stateConfig.rules || {};
            const currentEdge = savedSettings.stateConfig.rules[edgeId];
            savedSettings.stateConfig.rules[edgeId] = currentEdge? {...currentEdge, ruleName}: {
                source,
                target,
                edge: edgeId,
                ruleName
            }

            journeyStmStore.add(source, savedSettings)
        }

        return <ChoiceAction
                    edgeId={edgeId}
                    ruleName={ruleName}
                    sourceNode={sourceNode}
                    targetNode={targetNode}
                    onActionHandle={onActionHandle}
                    selected={selected}
        />
    } else {
        return null;
    }
}

function CustomEdge(props) {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        markerEnd,
        source,
        target,
        stmNodeSelectHandle,
        selected,
        data
    } = props
    const reactFlowInstance = useReactFlow();
    const onActionHandle = data => {
        stmNodeSelectHandle(data);
    }

    const edgeAction = getEdgeAction(reactFlowInstance, id, source, target, selected, onActionHandle, data);
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                //width={foreignObjectSize}
                //height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="flex flex-row items-center justify-center w-full h-1/2"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body>
                {edgeAction}
                </body>
            </foreignObject>
        </>
    );
}

const getEdgeActionV2 = (reactFlowInstance, edgeId, source, target, selected, onActionHandle, data={}, labelX, labelY, onDeleteEdge) => {
    const sourceNode = reactFlowInstance.getNode(source)
    const targetNode = reactFlowInstance.getNode(target)
    const { ruleName } = data;
    const { type } = sourceNode;

    let action = null
    if (CHOICE_NODE_TYPE === type) {
        if (CHOICE_DEFAULT_RULE_NAME !== ruleName) {
            const savedSettings = journeyStmStore.get(source) || new StmStateDto({
                type: CHOICE,
                stateConfig: { rules: {}, defaultState: '' },
            })
            savedSettings.stateConfig = savedSettings.stateConfig || { rules: {} };
            savedSettings.stateConfig.rules = savedSettings.stateConfig.rules || {};
            const currentEdge = savedSettings.stateConfig.rules[edgeId];
            savedSettings.stateConfig.rules[edgeId] = currentEdge? {...currentEdge, ruleName}: {
                source,
                target,
                edge: edgeId,
                ruleName
            }

            journeyStmStore.add(source, savedSettings)
        }

        action = <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan">
                    <ChoiceAction
                        edgeId={edgeId}
                        ruleName={ruleName}
                        sourceNode={sourceNode}
                        targetNode={targetNode}
                        onActionHandle={onActionHandle}
                        selected={selected}
                    />
                </div>
    } else {
        action =
            <div style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                fontSize: 12,
                // everything inside EdgeLabelRenderer has no pointer events by default
                // if you have an interactive element, set pointer-events: all
                pointerEvents: 'all',
            }}
                 className="nodrag nopan">
                <button
                    className="edgebutton"
                        onClick={onDeleteEdge}>
                    x
                </button>
            </div>

    }

    return <EdgeLabelRenderer>
        {action}
    </EdgeLabelRenderer>
}

function CustomEdgeV2({
                        id,
                        sourceX,
                        sourceY,
                        targetX,
                        targetY,
                        sourcePosition,
                        targetPosition,
                        style = {},
                        markerEnd, stmNodeSelectHandle,
                          source,
                          target,
                          selected,
                            data
                    }) {
    const reactFlowInstance = useReactFlow();
    const { setEdges } = useReactFlow();

    const onActionHandle = data => {
        stmNodeSelectHandle(data);
    }

    const onDeleteEdge = () => {
        setEdges((es) => es.filter((e) => e.id !== id))
    }

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });


    const edgeAction = getEdgeActionV2(reactFlowInstance, id, source, target, selected, onActionHandle, data, labelX, labelY, onDeleteEdge);
    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} interactionWidth={250} />
            {edgeAction}
        </>
    );
}

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        stmNodeSelectHandle: (message) => {
            dispatch(stmNodeSelect(message));
        },
    };
}
export default connect(mapsStateToProps, mapDispatchToProps)(CustomEdgeV2)