import './App.css';
import { connect } from 'react-redux';
import { AppContext, getDefaultContext } from './context/app-context';
import MainRouter from './pages/main-router';
import { loadAuthUserInfo } from './services/auth/auth-service';
import { useEffect, useState } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Flowbite} from 'flowbite-react';
import {useQuery} from "./helpers/hooks/common-hook";
import {getSettingsData, resetSettingsData, setSettingsData} from "./pages/client-settings/client-settings-data";
import { get } from "./helpers/utils/http-client";
import config from "./config";
import DashboardRouter from "./pages/dashboard-router";
import FormLoader from "./components/form-loader/form-loader";

const mapsStateToProps = (state, ownProps) => {
    return {
        appInfoMessage: state.appInfoMessage
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

function App({appInfoMessage}) {
    const location = useLocation();
    const query = useQuery();
    const url = query.get("url");
    const token = query.get("token");
    const accessToken = query.get("access_token");

    const navigation = useNavigate();
    const [authUserInfo, setAuthUserInfo] = useState();
    const [appInfo, setAppInfo] = useState( );
    const [settingsState, setSettingsState] = useState(getSettingsData());

    async function loadContext() {
        const authUser = await loadAuthUserInfo();
        if (authUser) {
            setAuthUserInfo(authUser);
        }
    }

    async function init() {
        // listens auth check
        // Hub.listen('auth', async (data) => {
        //     const event = data.payload.event;
        //     if (event === "signIn") {
        //         await loadContext();
        //     } else if (event === "signOut") {
        //         // TODO
        //     }
        // });
        await loadContext();
    }

    useEffect(() => {
        init();
    }, []);

    function isChatPluginPath() {
        const {pathname} = location;
        return pathname.includes("web-chat-plugin") || pathname.includes("chat");
    }

    const loadSettings = async () => {
        if (url && token) {
            resetSettingsData()
            console.log('RESET PREVIOUS SETTINGS')
            let params = {};
            try {
                let urlPath = url.replace(`${config.API_BASE_URL}/`, '')
                urlPath = urlPath.replace(config.KONG_BASE_URL, '')
                const { response } = await get(`${config.API_BASE_URL}/client-settings/${urlPath}`)
                params = response
            } catch (e) {
                console.log('Setting fetch failed', e)
            }

            const data = {
                url, token, accessToken, ...params
            }
            setSettingsData(data)
            setSettingsState(data)
        }
    }

    useEffect(() => {
        loadSettings()
    }, [url, token]);

    useEffect(() => {
        if (appInfoMessage && appInfoMessage.data) {
            setAppInfo(appInfoMessage.data);
        }
    }, [appInfoMessage]);

    if (!isChatPluginPath() && (!accessToken && !getSettingsData().accessToken)) {
        window.location = config.LOGIN_URL
        return
    }

    return (
        <AppContext.Provider value={{ ...getDefaultContext(), authUserInfo, appInfo, settingsState }}>
            {settingsState?
                <Flowbite>
                    <MainRouter/>
                </Flowbite>
            : <FormLoader fullScreen={true} />}

        </AppContext.Provider>
    );
}

export default connect(mapsStateToProps, mapDispatchToProps)(App);
