import {useCallback, useRef, useState} from 'react';
import Button from '../../components/button/button';
import TextBox from "../../components/forms/text-box";
import {Modal} from "flowbite-react";
import Message from "../../components/toast-message";
import {setSettingsData, resetSettingsData, getSettingsData
} from "./client-settings-data";

export default function Settings({onClose}) {
    const rootRef = useRef(null);
    const [formData, setFormData] = useState(getSettingsData() || {
        client_id: '',
        chat_bot: '',
        language: '',
        stage: '',
    });

    const [error, setError] = useState({
        name: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const handleChange = useCallback(event => {
        formData[event.target.name] = event.target.value
        setFormData({...formData});
    }, []);

    const validateForm = () => {
        let valid = true;
        if (formData.client_id === '') {
            error.client_id = true;
            valid = false;
        } else {
            error.client_id = false;
        }

        if (formData.chat_bot === '') {
            error.chat_bot = true;
            valid = false;
        } else {
            error.chat_bot = false;
        }

        if (formData.language === '') {
            error.language = true;
            valid = false;
        } else {
            error.language = false;
        }

        if (formData.stage === '') {
            error.stage = true;
            valid = false;
        } else {
            error.stage = false;
        }

        setError({...error})
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            setSettingsData(formData)
            onClose()
            updateFormState(false, true, false, "Successfully saved");
        }
    };

    const handleOnReset = async (event) => {
        event.preventDefault();
        resetSettingsData()
        window.location = ''
    };

    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={onClose} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Client Settings</h3>

                            {formState.success && <Message text="Successfully Save" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            {/*<TextBox*/}
                            {/*    type="text"*/}
                            {/*    name="client_id"*/}
                            {/*    id="client_id"*/}
                            {/*    label="Client ID"*/}
                            {/*    value={formData.client_id}*/}
                            {/*    placeholder="Enter Client ID"*/}
                            {/*    onChange={handleChange}*/}
                            {/*    error={error.client_id}*/}
                            {/*/>*/}

                            {/*<TextBox*/}
                            {/*    type="text"*/}
                            {/*    name="chat_bot"*/}
                            {/*    id="chat_bot"*/}
                            {/*    label="Chat Bot"*/}
                            {/*    value={formData.chat_bot}*/}
                            {/*    placeholder="Enter ChatBot"*/}
                            {/*    onChange={handleChange}*/}
                            {/*    error={error.chat_bot}*/}
                            {/*/>*/}

                            {/*<TextBox*/}
                            {/*    type="text"*/}
                            {/*    name="language"*/}
                            {/*    id="language"*/}
                            {/*    label="Language"*/}
                            {/*    value={formData.language}*/}
                            {/*    placeholder="Enter Language"*/}
                            {/*    onChange={handleChange}*/}
                            {/*    error={error.language}*/}
                            {/*/>*/}

                            {/*<TextBox*/}
                            {/*    type="text"*/}
                            {/*    name="stage"*/}
                            {/*    id="stage"*/}
                            {/*    label="Stage"*/}
                            {/*    value={formData.stage}*/}
                            {/*    placeholder="Enter Stage"*/}
                            {/*    onChange={handleChange}*/}
                            {/*    error={error.stage}*/}
                            {/*/>*/}

                            <TextBox
                                type="url"
                                name="url"
                                id="url"
                                label="URL"
                                value={formData.url}
                                placeholder="Enter URL"
                                onChange={handleChange}
                                error={error.url}
                            />

                            <TextBox
                                type="text"
                                name="token"
                                id="token"
                                label="Token"
                                value={formData.token}
                                placeholder="Enter Token"
                                onChange={handleChange}
                                error={error.token}
                            />

                            <div className="w-full flex flex-row gap-2 justify-between">
                                <Button color="default" text="Reset" onClick={handleOnReset}/>
                                <div className="w-full flex flex-row gap-2 justify-end">
                                    <Button color="default" text="Cancel" onClick={onClose}/>
                                    {formState.processing?
                                        <Button type="button" text="Save Settings" loading={true}/>
                                        :
                                        <Button type="submit" text="Save Data"/>
                                    }

                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}