import {useEffect, useState} from 'react';
import {useAppInfo, useQuery, useSettingsState} from '../../../helpers/hooks/common-hook';
import Button from "../../../components/button/button";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import PageContainer from "../../../components/page-container";
import Table from "../../../components/table";
import TableReloadButton from "../../../components/button/table-reload-button";
import { journeyList, journeyDelete } from "../../../services/journey/journey-service";
import JourneyCreate from "../create";
import JourneyCreateDialog from "../editor/journey/journey-create";
import TemplateSelectorDialog from "../../tempalte-selector";
import memoryDB from "../editor/memory-db";
import {useNavigate} from "react-router-dom";
import moment from "moment";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function JourneyList() {
    const { id: appId } = useAppInfo();
    const settingsState = useSettingsState()
    console.log('settingsState', settingsState)
    const [ dataList, setDataList ] = useState([]);
    const [ editData, setEditData ] = useState();
    const [enabled, setEnabled] = useState(true)
    const navigation = useNavigate();

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

    async function fetchConfigs() {
        updateFormState(true, false, false);
        const data = await journeyList();
        if (data) {
            setDataList(data);
        }
        updateFormState(false, true, false);
    }

    useEffect( () => {
        fetchConfigs();
    }, []);

    useEffect( () => {
        console.log('accessToken fetchConfigs')
        fetchConfigs();
    }, [settingsState]);

    const handleOpenCreate = () => {
        setOpenCreateDialog(true);
        setEditData()
    }

    const handleOnCompletionCreate = async state => {
        if (state) {
            await fetchConfigs();
        }
        setOpenCreateDialog(false);
        setEditData()
    }

    const handleSetEditData = data => {
        setEditData(data);
        setOpenCreateDialog(true);
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const handleSelectDelete = id => {
        setDeletingData(id);
        setDeleteRevokeConfirmDialog(true);
    }

    const handleConfirmDelete = async confirmed => {
        setDeleteRevokeConfirmDialog(false);
        if (confirmed) {
            updateFormState(true, false, false);
            await journeyDelete(deletingData);
            setDeletingData(null);
            await fetchConfigs();
            updateFormState(false, true, false);
        }
    }

    const [openJourneyCreate, setOpenJourneyCreate] = useState(false);
    const handleOnCancelJourneyCreate = () => {
        setOpenJourneyCreate(false);
    };

    const handleOnSuccessJourneyCreate = () => {
        setOpenJourneyCreate(false);
        console.log('setJourneyId')
        navigation('/workflow')
    }

    const [openTemplateSelector, setOpenTemplateSelector] = useState(false);
    const onOpenTemplateSelector = () => {
        setOpenTemplateSelector(true);
    };

    const onCloseTemplateSelector = (templateId) => {
        setOpenTemplateSelector(false);
        if (templateId) {
            window.location = `/workflow?q_template=${templateId}`
        }
    };

    const processDataList = Object.keys(dataList).map(key=>{
        const dt = JSON.parse(dataList[key])
        return {
            record: dt,
            columnsData: [
                { data: dt.topic, isIdField: true },
                { data: moment(dt.workflow.modifiedAt).local().format("YYYY-MM-DD HH:mm:ss"), isUpdatedField: true },
                { options: [
                        {
                            name: 'Workflow Editor',
                            onClick: ()=>{
                                window.location = `/workflow?q_topic=${dt.topic}`
                            }
                        },
                        {
                            name: 'Delete Workflow',
                            onClick: ()=>handleSelectDelete(dt.topic)
                        }
                    ]
                },
            ]
        }
    })

    return (
        <PageContainer title="Workflows" headerAction={
            <div className="flex flex-row items-center gap-4">
                <TableReloadButton onClick={() => fetchConfigs()}/>
                <Button
                    variant="button"
                    onClick={() => window.location = `/workflow`}
                    text="New Workflow"/>
                <Button
                    variant="button"
                    color="default"
                    onClick={onOpenTemplateSelector}
                    text="Create From Template"/>
            </div>
        }
        >

            {openJourneyCreate && (
                <JourneyCreateDialog
                    nameProp={memoryDB.data.topic}
                    contentProp={memoryDB.data.content}
                    onCompletion={handleOnSuccessJourneyCreate}
                    onCancel={handleOnCancelJourneyCreate}
                />
            )}

            {openTemplateSelector && (
                <TemplateSelectorDialog
                    onClose={onCloseTemplateSelector}
                />
            )}

            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    open={openDeleteConfirmDialog}
                    onCompletion={handleConfirmDelete}
                    title="Delete Confirmation"
                    message="Are you sure. Do you want to delete this Journey ?"
                />
            }

            <Table
                headers={['Name']}
                items={processDataList}
                dataLoadingState={formState}
                itemsNotFoundMessage="You don't have any Journeys"
            />

        </PageContainer>
    )
}