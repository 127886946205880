import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import Button from "../../../../../components/button/button";
import SlotSelector from "../../common/components/slot-selector";
import CodeEditorDialog from "../../common/components/code-editor-dialog";
import {Switch} from "@headlessui/react";
import TextBox from "../../../../../components/forms/text-box";
import SelectInput from "../../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";
import DynamicRows from "../../../../../components/dynamic-rows";
import SentToUserSwitch from "../../common/components/send-to-user-switch";
import {RadioButtonGroup} from "../../../../../components/radio-button";
import Editor from "@monaco-editor/react";
import { Dropdown } from 'flowbite-react';
import DropDown from "../../../../../components/forms/dropdown";
import {formDataRequestType, requestTypes} from "../../../../../consts/common-consts";
import PauseAfterExecutionSwitch from "../../common/components/pause-after-execution-switch";

const prepareRawsData = (data=[]) => {
    return data.map(header=>({inputs: header}))
}

const customDropDownTheme = {
    "arrowIcon": "ml-2 h-4 w-4",
    "content": "py-1 focus:outline-none",
    "floating": {
        "animation": "transition-opacity",
        "arrow": {
            "base": "absolute z-10 h-2 w-2 rotate-45",
            "style": {
                "dark": "bg-gray-900 dark:bg-gray-700",
                "light": "bg-white",
                "auto": "bg-white dark:bg-gray-700"
            },
            "placement": "-4px"
        },
        "base": "z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none",
        "content": "py-1 text-sm text-gray-700 dark:text-gray-200",
        "divider": "my-1 h-px bg-gray-100 dark:bg-gray-600",
        "header": "block py-2 px-4 text-sm text-gray-700 dark:text-gray-200",
        "hidden": "invisible opacity-0",
        "item": {
            "container": "",
            "base": "flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white",
            "icon": "mr-2 h-4 w-4"
        },
        "style": {
            "dark": "bg-gray-900 text-white dark:bg-gray-700",
            "light": "border border-gray-200 bg-white text-gray-900",
            "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
        },
        "target": "w-fit"
    },
    "inlineWrapper": "flex items-center"
}

function Page({name, onChangeData, savedSettings, metadata={}}) {
    const [formData, setFormData] = useState(savedSettings || {
        name,
        type: 'webhook',
        slotToAssign: '',
        skipNotify: true,
        pauseAfterExecution: false,
        defaultInput: '{}',
        webhookConfig: {
            url: '',
            method: 'GET',
            parameters: [],
            requestFormData: [],
            requestBody: '{}',
            requestType: 'form_data',
            headers: [],
            responseMapping: [],
        },
        requestMapper: {
            type: 'inline_script',
            inlineScript: ''
        },
        responseMapper: {
            type: 'inline_script',
            inlineScript: ''
        }
    });

    const [openCodeEditor, setOpenCodeEditor] = useState();

    const [requestType, setRequestType] = useState();

    const notifyOnchangeData = data => {
        onChangeData(new StmStateDto({
            type: ACTION_PROMPT,
            stateConfig: data
        }));
    }

    useEffect(() => {
        notifyOnchangeData(formData);
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'slot') {
            newFormData.slotToAssign = targetValue;
        }

        if (targetName === 'autoFillFromEntity') {
            newFormData.autoFillFromEntity = targetValue;
        }

        if (targetName === 'url') {
            newFormData.webhookConfig.url = targetValue;
        }

        if (targetName === 'method') {
            newFormData.webhookConfig.method = targetValue;
        }

        if (targetName === 'requestType') {
            newFormData.webhookConfig.requestType = targetValue;
        }

        setFormData(newFormData);
    };

    const onClickOpenCodeEditor = type => {
        setOpenCodeEditor(type)
    }

    const onCodeEditComplete = (code) => {
        setOpenCodeEditor(false)
        if (openCodeEditor === 'request' && code) {
            formData.requestMapper.inlineScript = code;
        } else if (openCodeEditor === 'response' && code) {
            formData.responseMapper.inlineScript = code;
        }
        setFormData({...formData});
    }

    const onDynamicRowChange = (rowsData, field) => {
        rowsData = rowsData.map(raw=>raw.inputs)
        const newFormData = {...formData};
        newFormData.webhookConfig[field] = rowsData
        notifyOnchangeData(newFormData);
    }

    const onChangeParameters = (rowsData=[]) => {
        onDynamicRowChange(rowsData, 'parameters');
    }

    const onChangeHeaders = (rowsData=[]) => {
        onDynamicRowChange(rowsData, 'headers');
    }

    const onChangeFormData = (rowsData=[]) => {
        onDynamicRowChange(rowsData, 'requestFormData');
    }

    const onChangeResponseMapper = (rowsData=[]) => {
        onDynamicRowChange(rowsData, 'responseMapping');
    }

    return (
        <div className="flex flex-col gap-2">

            {(openCodeEditor === 'request') &&
                <CodeEditorDialog
                    open={true}
                    title="Request Handler"
                    code={formData.requestMapper.inlineScript}
                    onCompletion={onCodeEditComplete}/>
            }

            {(openCodeEditor === 'response') &&
                <CodeEditorDialog
                    open={true}
                    title="Response Handler"
                    code={formData.responseMapper.inlineScript}
                    onCompletion={onCodeEditComplete}/>
            }

            <SettingTip text="The API Step allows you to making custom API calls that involve retrieving data from an external database or sending data to another source"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            {/*<SlotSelector value={formData.slotToAssign} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />*/}

            <SentToUserSwitch formData={formData} setFormData={setFormData} skipNotify={formData.skipNotify}/>

            <PauseAfterExecutionSwitch formData={formData} setFormData={setFormData}
                                       pauseAfterExecution={formData.pauseAfterExecution}/>

            <div className="mt-4 pt-4 border-t-2 border-gray-100 flex flex-row justify-between items-center gap-2">
                <TextBox
                    rootClass="flex-1"
                    label="API"
                    type="url"
                    name="url"
                    placeholder="Enter URL with {parameters}"
                    value={formData.webhookConfig.url}
                    onChange={handleChange}
                />

                <DropDown
                    className="pt-5"
                    placeHolder="Method"
                    name="method"
                    value={formData.webhookConfig.method}
                    options={['GET', 'POST', 'PUT', 'DELETE']}
                    onChange={handleChange}
                />
            </div>

            <DynamicRows
                title="Parameters"
                className=""
                rowsData={prepareRawsData(formData.webhookConfig.parameters)}
                onChange={onChangeParameters}
                showTopDivider={false}
                showBottomDivider={false}
                rowsTemplate={(valuesMap, handleInputChange) => (
                    <>
                        <TextBox
                            rootClass=""
                            label="Name"
                            type="text"
                            name="name"
                            placeholder="Header name"
                            value={valuesMap.name}
                            onChange={handleInputChange}
                        />
                        <TextBox
                            rootClass=""
                            label="Value"
                            type="text"
                            name="value"
                            placeholder="Value with {slot}"
                            value={valuesMap.value}
                            onChange={handleInputChange}
                        />
                    </>
                )}
            />

            {formData.webhookConfig.method !== "GET" &&
                <div className="border-t-2 border-gray-100 pt-4 mt-4">
                    <div className="pb-4 flex flex-row justify-end items-center">
                        <RadioButtonGroup
                            selectedValue={formData.webhookConfig.requestType || formDataRequestType.value}
                            options={requestTypes}
                            onChange={handleChange}
                            name="requestType"/>
                    </div>

                    {formData.webhookConfig.requestType === formDataRequestType.value ?
                        <DynamicRows
                            title="Form Data"
                            className=""
                            rowsData={prepareRawsData(formData.webhookConfig.requestFormData)}
                            onChange={onChangeFormData}
                            showTopDivider={false}
                            showBottomDivider={false}
                            rowsTemplate={(valuesMap, handleInputChange) => (
                                <>
                                    <TextBox
                                        rootClass=""
                                        label="Name"
                                        type="text"
                                        name="name"
                                        placeholder="Field name"
                                        value={valuesMap.name}
                                        onChange={handleInputChange}
                                    />
                                    <TextBox
                                        rootClass=""
                                        label="Value"
                                        type="text"
                                        name="value"
                                        placeholder="Value with {slot}"
                                        value={valuesMap.value}
                                        onChange={handleInputChange}
                                    />
                                </>
                            )}
                        />
                        :
                        <Editor
                            height="20vh"
                            defaultLanguage='json'
                            value={formData.webhookConfig.requestBody || '{}'}
                            theme="vs-dark"
                            onChange={value=>{
                                const newFormData = {...formData};
                                newFormData.webhookConfig.requestBody = value
                                setFormData(newFormData);
                            }}
                        />
                    }
                </div>

            }

            <DynamicRows
                title="Headers"
                className="pt-2"
                rowsData={prepareRawsData(formData.webhookConfig.headers)}
                onChange={onChangeHeaders}
                showBottomDivider={false}
                rowsTemplate={(valuesMap, handleInputChange) => (
                    <>
                        <TextBox
                            rootClass=""
                            label="Name"
                            type="text"
                            name="name"
                            placeholder="Header name"
                            value={valuesMap.name}
                            onChange={handleInputChange}
                        />
                        <TextBox
                            rootClass=""
                            label="Value"
                            type="text"
                            name="value"
                            placeholder="Value with {slot}"
                            value={valuesMap.value}
                            onChange={handleInputChange}
                        />
                    </>
                )}
            />

            <DynamicRows
                title="Response Mapping"
                className="pt-2"
                removeButtonClassName="pt-0 flex-row justify-end"
                rowsData={prepareRawsData(formData.webhookConfig.responseMapping)}
                onChange={onChangeResponseMapper}
                showBottomDivider={false}
                rowsTemplate={(valuesMap, handleInputChange) => (
                    <div className="flex flex-row items-center gap-2">
                        <TextBox
                            rootClass=""
                            label="Json Path"
                            type="text"
                            name="key"
                            placeholder="Json Path"
                            value={valuesMap.key}
                            onChange={handleInputChange}
                        />
                        <TextBox
                            rootClass=""
                            label="Variable"
                            type="text"
                            name="slot"
                            placeholder="Apply To"
                            value={valuesMap.slot}
                            onChange={handleInputChange}
                        />
                        {/*<DropDown*/}
                        {/*    className=""*/}
                        {/*    placeHolder="Apply To"*/}
                        {/*    name="slot"*/}
                        {/*    value={valuesMap.slot}*/}
                        {/*    options={Object.keys(metadata.slots)}*/}
                        {/*    onChange={handleInputChange}*/}
                        {/*/>*/}
                    </div>
                )}
            />

            {/*<div className="grid grid-cols-1">*/}
            {/*    <Button text="Request Mapper" color="default" onClick={()=>onClickOpenCodeEditor('request')} />*/}
            {/*</div>*/}

            {/*<div className="grid grid-cols-1">*/}
            {/*    <Button text="Response Mapper" color="default" onClick={()=>onClickOpenCodeEditor('response')} />*/}
            {/*</div>*/}

        </div>
    )
}

export default Page