import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../../../../../components/button/button';
import Message from "../../../../../components/message/message-comp";
import Loader from "../../../../../components/form-loader/form-loader";
import { appCreateNotify } from "../../../../../redux/app/app-actions";
import { Switch } from '@headlessui/react'
import {getProcessOP, OPERATORS, OPERATORS_VALUE_TYPE, VALUE_NEED_OPERATORS} from "./rule-data";
import SelectInput from "../../../../../components/forms/select";

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        appCreateNotifyHandle: (appId) => {
            dispatch(appCreateNotify(appId));
        },
    };
}

const defaultStatement = {
    variable: '',
    operator: 'matches string',
    valueType: '',
    value: '',
    notOp: false,
}

function ConditionRow({statement, onChange, statementId, metadata={slots: {}}}) {
    const [formData, setFormData] = useState(statement || defaultStatement);

    useEffect( () => {
        if (formData.operator) {
            const op = getProcessOP(formData.operator, formData.valueType)
            if (op) {
                const condition = { "Variable": formData.variable }
                condition[op.proceedOperator] = formData.value || true;
                const finalCondition = formData.notOp? { "Not": condition }: condition;
                onChange(statementId, finalCondition, formData);
            }
        }
    }, [formData]);

    useEffect( () => {
        if (formData.operator) {
            setFormData({...formData, valueType: ""})
        }
    }, [formData.operator]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'variable') {
            formData.variable = targetValue;
        }

        else if (targetName === 'operator') {
            formData.operator = targetValue;
        }

        else if (targetName === 'valueType') {
            formData.valueType = targetValue;
        }

        else if (targetName === 'value') {
            formData.value = targetValue;
        }

        setFormData(Object.assign({}, formData));
    });

    const handleNotOpChange = useCallback(checked => {
        setFormData({...formData, notOp: checked});
    })

    const isValueEnable = VALUE_NEED_OPERATORS.includes(formData.operator);

    return (
        <div className="grid grid-cols-5 gap-4 border border-gray-200 rounded-md p-2">
            <div className="flex-grow">
                <label className="text-gray-800 font-semibold block my-3 text-sm"
                       htmlFor="condition-type">Not</label>
                <Switch
                    checked={formData.notOp}
                    onChange={handleNotOpChange}
                    className={`${
                        formData.notOp ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                    <span className="sr-only">Enable notifications</span>
                    <span
                        className={`${
                            formData.notOp ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white`}
                    />
                </Switch>
            </div>

            <div className="flex-grow">
                <label className="text-gray-800 font-semibold block my-3 text-sm"
                       htmlFor="condition-type">Variable</label>
                <input
                    className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                    type="text"
                    name="variable"
                    id="variable"
                    value={formData.variable}
                    placeholder="Enter variable"
                    onChange={handleChange}
                    required
                />
            </div>

            {/*<SelectInput*/}
            {/*    rootClass="pt-3"*/}
            {/*    labelClass="font-semibold"*/}
            {/*    label="Variable"*/}
            {/*    id="variable"*/}
            {/*    name="variable"*/}
            {/*    autoComplete="variable"*/}
            {/*    value={formData.variable}*/}
            {/*    onChange={handleChange}*/}
            {/*    optionsComp={<>*/}
            {/*        <option value={undefined}>Select Variable</option>*/}
            {/*        {Object.keys(metadata.slots).map((slotName, si)=>(*/}
            {/*            <option key={si} value={slotName}>{slotName}</option>*/}
            {/*        ))}*/}
            {/*    </>}*/}
            {/*/>*/}

            {/*<div className="flex-grow">*/}
            {/*    <label className="text-gray-800 font-semibold block my-3 text-sm"*/}
            {/*           htmlFor="condition-type">Operator</label>*/}
            {/*    <select*/}
            {/*        required*/}
            {/*        name="operator"*/}
            {/*        value={formData.operator}*/}
            {/*        onChange={handleChange}*/}
            {/*        className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"*/}
            {/*    >*/}
            {/*        {OPERATORS.map((op, opIndex)=><option key={opIndex} value={op}>{op}</option>)}*/}
            {/*    </select>*/}
            {/*</div>*/}

            <SelectInput
                rootClass="pt-3"
                labelClass="font-semibold"
                label="Operator"
                required
                name="operator"
                value={formData.operator}
                onChange={handleChange}
                optionsComp={
                <>
                    {OPERATORS.map((op, opIndex)=><option key={opIndex} value={op}>{op}</option>)}
                </>}
            />

            <div className="flex-grow">
                <label className="text-gray-800 font-semibold block my-3 text-sm"
                       htmlFor="condition-type">Value</label>
                <input
                    className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                    type="text"
                    name="value"
                    id="value"
                    value={formData.value}
                    placeholder="Value"
                    onChange={handleChange}
                />
            </div>

        </div>
    );
}

function Index({ rule, open, onCompletion, onCancel, savedKnowledgeBase={}, metadata={} }) {
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [statements, setStatements] = useState((rule.metadata? rule.metadata.statements: null) || [defaultStatement]);

    const [conditionType, setConditionType] = useState((rule.metadata? rule.metadata.conditionType: null) || "simple");

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        if (onCancel) {
            onCancel();
        } else {
            onCompletion();
        }
    });

    const handleOnChangeCondition = (statementId, condition, metadata) => {
        const newStatements = [...statements];
        newStatements[statementId] = { condition, metadata };
        setStatements(newStatements);
    };

    const handleChangeConditionType = useCallback(event => {
        const targetValue = event.target.value;
        setConditionType(targetValue);

        if (targetValue === 'simple') {
            setStatements([{}]);
        }

    });

    const handleAddStatement = () => {
        const newStatements = [...statements];
        newStatements.push({})
        setStatements(newStatements);
    };

    const handleRemoveStatement = () => {

    };

    const handleOnSubmit = async (event) => {
        let choice;
        if (conditionType === 'simple') {
            const { condition } = statements[0]; // always one
            choice = condition;
        } else if (conditionType === 'and') {
            choice = {
                "And": statements.map(({condition})=>condition)
            }
        } else if (conditionType === 'or') {
            choice = {
                "Or": statements.map(({condition})=>condition)
            }
        }

        choice["Next"] = rule.target
        const metadata = {
            conditionType,
            statements
        }
        onCompletion(rule, choice, metadata)
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" initialFocus={cancelButtonRef} onClose={()=>{}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-5xl w-full">
                                <div className="bg-white px-4 pt-5 pb-4">
                                    <div className="flex items-start">
                                        <div className="flex-grow">
                                            <Dialog.Title as="h3" className="text-center text-2xl text-gray-600 font-bold">
                                                Add Rule
                                            </Dialog.Title>

                                            <div className="flex flex-col">

                                                <div className="flex-grow col-span-6">
                                                    <label className="text-gray-800 font-semibold block my-3 text-md"
                                                           htmlFor="condition-type">Condition Type</label>
                                                    <select
                                                        required
                                                        name="conditionType"
                                                        value={conditionType}
                                                        onChange={handleChangeConditionType}
                                                        className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                                                    >
                                                        <option value="simple" selected={true}>Simple - Evaluate single conditional statement</option>
                                                        <option value="and">AND - Evaluate multiple statements. Return true if all statements are true</option>
                                                        <option value="or">OR - Evaluate multiple statements. Return true if any statement is true</option>
                                                    </select>
                                                </div>

                                                {statements.map((statement, statementId)=>
                                                    <div className="flex-grow col-span-6 py-2">
                                                        <ConditionRow statement={statement.metadata} onChange={handleOnChangeCondition} statementId={statementId} metadata={metadata}/>
                                                    </div>
                                                )}

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 bg-gray-50 px-4 py-3 px-6 gap-2">
                                    <div>
                                        {conditionType !== 'simple' &&
                                            <button
                                                type="button"
                                                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                                                onClick={handleAddStatement}
                                                ref={cancelButtonRef}
                                            >
                                                Add
                                            </button>
                                        }
                                    </div>

                                    <div className="flex flex-row gap-2 justify-end">
                                        <Button variant="button" color="default" text="Cancel" onClick={handleOnCancel} />
                                        <Button variant="button" type="submit" text="Add Rule" onClick={handleOnSubmit} />
                                    </div>
                                </div>

                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)