import {Route, Routes} from 'react-router-dom';
import {useAppInfo} from "../helpers/hooks/common-hook";

import Page from '../components/page/page';
import ApplicationsList from "./applications/list";
import JourneyList from "./journey/list";
import JourneyEditor from "./journey/editor";
import Chat from "./chat";
import {APP_BASE_PATH} from "../consts/route-consts";

const DashboardRouter = () => {
    const appInfo = useAppInfo();
    return (
        <Routes>
            {/*<Route path={`/`} element={<Page title="Applications" content={<ApplicationsList/>}/>}/>*/}
            {/*<Route path={`/applications`} element={<Page title="Applications" content={<ApplicationsList/>}/>}/>*/}

            {/*<Route path={`${APP_BASE_PATH}/journeys`}*/}
            {/*       element={<Page title="Journeys" content={<JourneyList/>}/>}/>*/}
            {/*<Route path={`${APP_BASE_PATH}/journeys/:journeyId/editor`}*/}
            {/*       element={<Page title="Journeys" content={<JourneyEditor/>}/>}/>*/}


            <Route path={`/`} element={<Page title="Workflow" content={<JourneyList/>}/>}/>
            <Route path={`/workflow`} element={<Page title="Workflow" content={<JourneyEditor/>}/>}/>
            <Route path={`/chat`} element={<Page title="Chat" content={<Chat/>}/>}/>
            <Route path={`/web-chat-plugin`} element={<Page title="Chat" content={<Chat/>}/>}/>

        </Routes>
    )
};

export default DashboardRouter;
