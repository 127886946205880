import React, {useEffect, useState} from "react";
import {StmStateDto} from "../common/dto/stm-state-dto";
import {QUESTION_PROMPT} from "../common/const/settings-type";
import SlotSelector from "../common/components/slot-selector";
import TextBox from "../../../../components/forms/text-box";
import SettingTip from "../common/components/setting-tip";
import DisplayTexts from "../common/components/display-texts";

function QuestionPromptSettings({ name, onChangeData, savedSettings, metadata }) {
    const [formData, setFormData] = useState(savedSettings || {
        name,
        slot: '',
        displayTextOptions: [],
        type: 'text'
    });

    const notifyChanges = (_formData) => {
        onChangeData(new StmStateDto({
            type: QUESTION_PROMPT,
            stateConfig: _formData
        }));
    }

    useEffect(() => {
        notifyChanges(formData)
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'slot') {
            newFormData.slot = targetValue;
        }

        if (targetName === 'displayText') {
            newFormData.displayText = targetValue;
        }

        if (targetName === 'autoFillFromEntity') {
            newFormData.autoFillFromEntity = targetValue;
        }

        setFormData(newFormData);
    };

    const handleDisplayTextsChange = data => {
        const newFormData = {...formData, displayTextOptions: data}
        notifyChanges(newFormData)
    }

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="The prompt sent to the user, answer is stored in the slot"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <SlotSelector value={formData.slot} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />

            {/*<TextArea*/}
            {/*    label="Display Text"*/}
            {/*    type="text"*/}
            {/*    name="displayText"*/}
            {/*    placeholder="User display text"*/}
            {/*    value={formData.displayText}*/}
            {/*    onChange={handleChange}*/}
            {/*/>*/}

            <DisplayTexts
                parameterName={formData.slot}
                displayTextOptionsProps={formData.displayTextOptions}
                onChangeData={handleDisplayTextsChange}
            />

        </div>
    )
}

export default QuestionPromptSettings