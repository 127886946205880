import {del, get, post, put} from "../../helpers/utils/http-client";
import {API_PREFIX, APPS_ENDPOINT, JOURNEYS_ENDPOINT} from "../../consts/api-endpoints";
import config from "../../config";
import {getSettingsData} from "../../pages/client-settings/client-settings-data";

export async function journeyList() {
    try {
        const settings = getSettingsData()
        const { response, status } = await get(`${config.API_BASE_URL}/topics/${settings.path_key}`, {  }, {
            'Authorization': `Bearer ${settings.accessToken}`
        });
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('journey list failed cause', err.message, err.code);
        return [];
    }
}

export async function journeyGet(appId, id) {
    try {
        const { response, status } = await get(`${JOURNEYS_ENDPOINT}/${id}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('journey get failed cause', err.message, err.code);
        return {};
    }
}

export async function journeyCreate(request) {
    try {
        return await post(JOURNEYS_ENDPOINT, request);
    } catch (err) {
        console.debug('journey create failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeyUpdate(id, request) {
    try {
        return await put(`${JOURNEYS_ENDPOINT}/${id}`, request);
    } catch (err) {
        console.debug('journey update failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeyDefinitionSave(id, request) {
    try {
        return await put(`${JOURNEYS_ENDPOINT}/${id}/stm`, request);
    } catch (err) {
        console.debug('journey definition save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeySlotSave(appId, journeyId, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}/slots`, data);
    } catch (err) {
        console.debug('journeySlotSave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeyDelete(id) {
    try {
        const settings = getSettingsData()
        await del(`${config.API_BASE_URL}/topics/${settings.path_key}/${id.toLowerCase()}`, {
            'Authorization': `Bearer ${settings.accessToken}`
        });
    } catch (err) {
        console.debug('journey delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export function journeySchemaDownload(id) {
    console.log('schema', `${JOURNEYS_ENDPOINT}/${id}/schema`)
    window.open(`${JOURNEYS_ENDPOINT}/${id}/schema`, '_blank');

}