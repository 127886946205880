import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {CHOICE, CHOICE_DEFAULT_RULE_NAME} from "../../common/const/settings-type";
import RuleAdd from "./rule-add";
import Message from "../../../../../components/message/message-comp";
import journeyStmStore from "../../common/service/journey-stm-store";
import {stmEdgeAdd, stmNodeSelect} from "../../../../../redux/journey-toolbox/journey-toolbox-actions";
import {connect} from "react-redux";
import TextBox from "../../../../../components/forms/text-box";
import SelectInput from "../../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";

const truncateText = (text, size=20) => {
    return text? text.substring(0, size) + "...": "...";
}

function RuleEditButton({rule, conditionText, onClick}) {
    return <button
        onClick={onClick}
        className="inline-flex justify-start py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gray-200 hover:bg-gray-500 text-gray-600 hover:text-gray-50">
        <div className="flex-1 flex flex-row gap-2 justify-between items-center">
            <div className="flex flex-col items-start">
                <div className="text-sm">{rule.ruleName}</div>
                <p className="text-xs">{truncateText(conditionText)}</p>
            </div>
            <div className="flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </div>
        </div>
    </button>
}

const statesForDefault = myId => {
    return Object.keys(journeyStmStore.getAll()).filter(id=>id !== myId).map(id=>({
        id,
        name: journeyStmStore.get(id).stateConfig.name
    }));
}

function Page({id, name, onChangeData, savedSettings, stmEdgeAddHandle, closeSettingPage=()=>{}, metadata={}}) {

    const [formData, setFormData] = useState(savedSettings || {
        name,
        defaultState: '',
        rules: []
    });
    const [editRule, setEditRule] = useState();
    const [openAddRuleDialog, setOpenAddRuleDialog] = useState(false);

    useEffect(() => {
        // rule add detect
        if (savedSettings) {
            setFormData(savedSettings);
        }
    }, [savedSettings]);

    const notifyChanges = (newFormData) => {
        onChangeData(new StmStateDto({
            type: CHOICE,
            stateConfig: newFormData
        }));
    }

    useEffect(() =>  {
        notifyChanges(formData)
    }, [formData]);

    useEffect(() => {
        if (editRule) {
            setOpenAddRuleDialog(true);
           // closeSettingPage()
        }
    }, [editRule]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        setFormData(newFormData);
    };

    const handleOpenAddRuleDialog = rule => {
        setEditRule(rule)
    }

    const handleOnCompletionAddRuleDialog = (rule, choice, metadata) => {
        try {
            const savedRue = formData.rules[rule.edge]
            if (savedRue) {
                savedRue.choice = choice;
                savedRue.metadata = metadata;
                formData.rules[rule.edge] = savedRue;
                setFormData({...formData});
            }
        } catch (e) {
            console.log("rule edit failed", e)
        }

        setEditRule()
        setOpenAddRuleDialog(false);
    }

    const handleChangeDefaultAction = event => {
        const edgeIdPrefix = 'reactflow__edge';
        const targetState = event.target.value;
        // get reference from journey-studio.index.getEdgeData()
        stmEdgeAddHandle({
            newEdge: {
                id: `${edgeIdPrefix}-${id}-${targetState}`,
                source: id,
                sourceHandle: "out",
                target: targetState,
                targetHandle: "in",
                markerEnd: {
                    type: "arrowclosed"
                },
                type: "customEdge",
                selected: true,
                data: {
                    ruleName: CHOICE_DEFAULT_RULE_NAME
                }
            },
            singleConnection: true,
        })
        const newFormData = {...formData, defaultState: targetState}
        notifyChanges(newFormData)
        // setFormData({...formData, defaultState: targetState});
    }

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="Conditional state enable to execute varying actions based on if, then logic"/>

            {(openAddRuleDialog) &&
                <RuleAdd
                    rule={editRule}
                    open={openAddRuleDialog}
                    onCompletion={handleOnCompletionAddRuleDialog}
                    metadata={metadata}
                />
            }

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Rules
            </label>

            {(formData.rules && (Object.keys(formData.rules).length > 0)) ?
                <div className="p-2 border-2 border-gray-100 rounded-lg w-full">
                    <div className="flex flex-col gap-2 w-full">
                        {Object.keys(formData.rules).map((ruleKey, ruleIndex)=> {
                                const rule = formData.rules[ruleKey];
                                return (<RuleEditButton
                                    key={rule.edge}
                                    rule={rule}
                                    onClick={() => handleOpenAddRuleDialog(rule)}
                                    conditionText={rule.conditionText}
                                />)
                            }
                        )
                        }
                    </div>
                </div>
                :
                <span className="rounded p-2 text-center text-sm text-gray-500 bg-gray-200">No Rules</span>
            }

            <SelectInput
                label="Default State"
                required
                name="defaultState"
                value={formData.defaultState}
                onChange={handleChangeDefaultAction}
                optionsComp={<>
                    <option className="text-gray-500" value="" selected={true}>Select state</option>
                    {statesForDefault(id).map((state, si)=> (
                        <option key={si} value={state.id}>{state.name}</option>
                    ))}
                </>}
            />

        </div>
    )
}

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        stmEdgeAddHandle: (message) => {
            dispatch(stmEdgeAdd(message));
        },
    };
}

export default connect(mapsStateToProps, mapDispatchToProps)(Page)