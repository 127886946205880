import {Spinner, Button} from 'flowbite-react';

const fullScreenCss = "fixed inset-0 bg-white bg-opacity-0 flex justify-center items-center"
const relativeFullScreenCss = "h-screen flex flex-col bg-white justify-center items-center"


function Loader({text}) {
    return (<div className="flex justify-center items-center">
            {text?
                <Button color="gray">
                    <Spinner aria-label="Alternate spinner button example"/>
                    <span className="pl-3">
                      {text}
                    </span>
                </Button>
            :<Spinner aria-label="Alternate spinner button example"/>
            }
    </div>)
}

export default function FormLoader({fullScreen, text, relative}) {
    let fullScreenClassFinal = ''
    if (fullScreen && relative) {
        fullScreenClassFinal = relativeFullScreenCss;
    } else if (fullScreen) {
        fullScreenClassFinal = fullScreenCss;
    }

    return (<>
        {fullScreen ?
            <div className={fullScreenClassFinal}><Loader text={text}/></div>
            : <Loader text={text}/>}
    </>)
}