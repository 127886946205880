export default function TextAreaWrapper(props) {
    const error = props.error;
    const rootClass = props.rootClass;
    const id = props.id;
    const label = props.label;
    const errorMessage = props.errorMessage || "Please fill out this field";

    const errorLabelClassName = error ? "text-red-700 dark:text-red-500" : "text-gray-900 dark:text-white";
    const errorTextAreaClassName = error ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500" : "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

    return (
        <div className={rootClass}>
            <div id={id}>
                <label htmlFor={id} className={`block text-sm font-medium ${errorLabelClassName}`}>
                    {label}
                </label>
                {props.subLabel &&
                    <span as="span" className="text-sm text-gray-500 dark:text-gray-500">
                    {props.subLabel}
                    </span>
                }
                <div className="pt-2">
                    <textarea id={id} className={errorTextAreaClassName} {...props} />
                    {error && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span className="font-medium">{errorMessage}</span>
                        </p>
                    )}
                </div>

            </div>
        </div>
    );
}
