import {stmSettingSave} from "./journey-toolbox-actions";

export function journeyToolboxItemAddMessage(
    state = {
        type: null
    }, action){

    switch(action.type){
        case 'JOURNEY_TOOLBOX_ITEM_ADD':{
            return Object.assign({}, state, action.payload);
        }
        default:{
            return state;
        }
    }
}

export function stmNodeSelectMessage(
    state = {
        id: null,
        type: null
    }, action){

    switch(action.type){
        case 'STM_NODE_SELECT':{
            return Object.assign({}, state, action.payload);
        }
        default:{
            return state;
        }
    }
}

export function stmSettingSaveMessage(
    state = {}, action){

    switch(action.type){
        case 'STM_SETTING_SAVE':{
            state[action.payload.id] = action.payload;
            return Object.assign({}, state);
        }
        default:{
            return state;
        }
    }
}

export function stmEdgeAddMessage(
    state = {
        newEdge: null
    }, action){

    switch(action.type){
        case 'STM_EDGE_ADD':{
            return Object.assign({}, state, action.payload);
        }
        default:{
            return state;
        }
    }
}

export function stmSlotCreateRequestMessage(
    state = false, action){

    switch(action.type){
        case 'STM_SLOT_CREATE_REQUEST':{
            return Object.assign({}, state, action.payload);
        }
        default:{
            return state;
        }
    }
}